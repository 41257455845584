const initialState = {
    countryCode: '',
  };
  
  const countryCodeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_COUNTRY_CODE':
        return {
          ...state,
          countryCode: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default countryCodeReducer;
  