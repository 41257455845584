import React, { useEffect } from 'react';
import '../Resources/assest/css/style.css';
import fb from '../Resources/assest/img/fb.webp';
import plane from '../Resources/assest/img/Plane_vector_img.png'
import flightw from '../Resources/assest/img/FlightW.webp';
import pinterest from '../Resources/assest/img/pinterest.webp';
import rt from '../Resources/assest/img/rt.png'
import SearchEngine from '../Shared/SearchEngine';
import $ from 'jquery';
import 'slick-carousel';

export default function Destination() {
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <SearchEngine />
    <section class="rughtd rela odhyr">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Lorem, ipsum.</i></p>
                    <h2>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h2>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores cum ipsum, eveniet earum laborum quas? Ex distinctio placeat atque laboriosam repudiandae quod perspiciatis, veritatis quasi cupiditate earum molestiae reiciendis tempore?</p>
                    <div class="d-lg-flex">
                        <ul>
                            <li>Lorem ipsum dolor sit. </li>
                            <li>Lorem ipsum dolor sit. </li>
                            <li>Lorem ipsum dolor sit. </li>
                            <li>Lorem ipsum dolor sit. </li>
                            
                        </ul>
                        <ul class="ms-lg-4 ">
                            <li>Lorem ipsum dolor sit. </li>
                            <li>Lorem ipsum dolor sit. </li>
                            <li>Lorem ipsum dolor sit. </li>
                            <li>Lorem ipsum dolor sit. </li>
                        </ul>
                    </div>
                   
                </div>
                <div class="col-lg-6 rela">
                    <img class="orfw rela" src={fb} alt="" />
                    <img class="ihtf" src={fb} alt="" />
                </div>
            </div>
        </div>
    </section>

    <section class="rela">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center mb-3">
              <img class="draw" src={plane} alt="" />
              <h2>Explore Your Dream Destinations Without Spending a Fortune! </h2>
              <p>Discover the most affordable flights to incredible destinations around the world with Fareslist. Experience the thrill of travel without the hefty price tag. Without compromising your budget, fly to Paris, Singapore, Australia, & more! </p>
            </div>
            <div class="col-md-6 col-lg-3">
              <a href="">
                <div class="rela mb-2 swert">
                  <img class="imge-round" src={flightw} alt="" width="100%" />
                   <marquee class=" read "><b>London</b></marquee>
                </div>
              </a>
            </div>
            <div class="col-md-6 col-lg-3">
              <a href="">
                <div class="rela mb-2 swert">
                  <img class="imge-round" src={flightw} alt="" width="100%" />
                   <marquee class=" read "><b>London</b></marquee>
                </div>
              </a>
            </div>
            <div class="col-md-6 col-lg-3">
              <a href="">
                <div class="rela mb-2 swert">
                  <img class="imge-round" src={flightw} alt="" width="100%" />
                   <marquee class=" read "><b>London</b></marquee>
                </div>
              </a>
            </div>
            <div class="col-md-6 col-lg-3">
              <a href="">
                <div class="rela mb-2 swert">
                  <img class="imge-round" src={flightw} alt="" width="100%" />
                   <marquee class=" read "><b>London</b></marquee>
                </div>
              </a>
            </div>
          </div>
        </div>
    </section>

    <section class="cvkfujg">
        <div class="container">
          <h2 class="text-center">Lorem ipsum dolor sit amet consectetu.</h2>
          <p class="text-center mb-4">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores deserunt voluptate saepe vero, neque ut vel voluptas dicta, quaerat repellat illo doloremque et sequi! Suscipit blanditiis officiis harum distinctio perferendis.</p>
          <div class="row">
            <div class="col-lg-12">
              <div class="slick-slider12 dtgd">
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={fb} alt="" width="100%" />
                      <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - NYC</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={fb} alt="" width="100%" />
                      <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - NYC</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={fb} alt="" width="100%" />
                      <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - NYC</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={fb} alt="" width="100%" />
                      <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - NYC</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={fb} alt="" width="100%" />
                      <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - NYC</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                
                
            </div>
        
            </div>
          </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={pinterest} alt="" width="100%" />
                </div>
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Lorem ipsum dolor.</i></p>
                    <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus repudiandae magni ullam reiciendis mollitia.</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas, ducimus exercitationem? Maxime nesciunt, eos, assumenda nisi tempora ut obcaecati voluptatem possimus facilis eius, reprehenderit voluptatibus quibusdam quidem. Dolor, deleniti quam. Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                </div>
                
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Lorem ipsum dolor.</i></p>
                    <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus repudiandae magni ullam reiciendis mollitia.</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas, ducimus exercitationem? Maxime nesciunt, eos, assumenda nisi tempora ut obcaecati voluptatem possimus facilis eius, reprehenderit voluptatibus quibusdam quidem. Dolor, deleniti quam. Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                    <a href="" target="_blank" class="d-flex align-items-center"><b><i>Contact Us</i></b> <img class="arrow-right1" src={rt} alt="" /></a>
                </div>
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={pinterest} alt="" width="100%" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
