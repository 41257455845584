import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/assest/css/style.css';
import SouthAfrica from '../Resources/assest/img/Main/SouthAfricaCountries/South-Africa.webp';
import Ethiopia from '../Resources/assest/img/Main/SouthAfricaCountries/Ethiopia.webp';
import Ghana from '../Resources/assest/img/Main/SouthAfricaCountries/Ghana.webp';
import Moritius from '../Resources/assest/img/Main/SouthAfricaCountries/Mauritius..webp';
import Kenya from '../Resources/assest/img/Main/SouthAfricaCountries/Kenya.webp';
import Morocco from '../Resources/assest/img/Main/SouthAfricaCountries/Morocco.webp';
import Senegal from '../Resources/assest/img/Main/SouthAfricaCountries/Senegal.webp';
import Tanzania from '../Resources/assest/img/Main/SouthAfricaCountries/Tanzania.webp';
import Madagaskar from '../Resources/assest/img/Small/AfricaCountries/Madagascar.webp';
import Algeria from '../Resources/assest/img/Small/AfricaCountries/Algeria.webp'
import Cameroon from '../Resources/assest/img/Small/AfricaCountries/Cameroon.webp'
import Libya from '../Resources/assest/img/Small/AfricaCountries/Libya.webp'
import namibia from '../Resources/assest/img/Small/AfricaCountries/Namibia.webp'
import Sudan from '../Resources/assest/img/Small/AfricaCountries/Sudan.webp'
import SearchEngine from '../Shared/SearchEngine';

export default function AfricanContinent() {
  return (
    <>
    <SearchEngine />
    <section class="rela">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mb-3">
            <h2>Reach Africa and Safari Through the Savanna</h2>
            <p>Safari across the Serengeti's golden plains or trek through Rwanda's misty mountains in search of gorillas. Egypt's ancient wonders and South Africa's diverse landscapes captivate the intrepid explorer.</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={SouthAfrica} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Ethiopia} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Ghana} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Moritius} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Kenya} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Morocco} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Senegal} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Tanzania} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          
        </div>
      </div>
    </section>

    <section class="cvkfujg">
      <div class="container text-center">
        <h2>Africa's Lesser-Known Delights: Offbeat Adventures Await</h2>
            <p class="mb-4">Book off the beaten path and uncover Africa's hidden delights, from the remote beauty of Namibia's deserts to the vibrant markets of Senegal. Explore the cultural richness of Malawi or trek through the untouched wilderness of Gabon.</p>
        <div class="row justify-content-center">
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Madagaskar} alt="" width="100%" />
              <p class="mt-2"><b>Madagascar</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Algeria} alt="" width="100%" />
              <p class="mt-2"><b>Algeria</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Cameroon} alt="" width="100%" />
              <p class="mt-2"><b>Cameroon</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Libya} alt="" width="100%" />
              <p class="mt-2"><b>Libya</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={namibia} alt="" width="100%" />
              <p class="mt-2"><b>Namibia</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Sudan} alt="" width="100%" />
              <p class="mt-2"><b>Sudan</b></p>
            </div>
            </Link>
          </div>

          <div class="mt-4 ">
            <Link to='/sitemap'>
              <button class="btnff"><i class="animationff"></i>View More<i class="animationff"></i>
            </button>
          </Link>
        </div>
         
        </div>
      </div>
    </section>
    
    
    <a id="button2424"></a>
    </>
  )
}
