import React, { useEffect } from 'react'
import '../Resources/assest/css/style.css'
import LosAngeles from '../Resources/assest/img/los-angeles.webp'
import LasVegas from '../Resources/assest/img/las-vegas.webp'
import JetBlue from '../Resources/assest/img/Jetblue-Airlines.webp'
import Spirit from '../Resources/assest/img/Spirit-Airlines.webp'
import United from '../Resources/assest/img/United-airlines.webp'
import American from '../Resources/assest/img/American-airlines.webp'
import Delta from '../Resources/assest/img/Delta.webp'
import LasVegas2 from '../Resources/assest/img/las-vegas2.webp'
import LasVegas3 from '../Resources/assest/img/las-vegas3.webp'
import $ from 'jquery';
import 'slick-carousel';
import SearchEngine from '../Shared/SearchEngine'

export default function LosLas() {
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <SearchEngine />
    <section class="rughtd rela odhyr">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Flights from Los Angeles to Las Vegas</i></p>
                    <h2>Budget-Friendly Flights from Los Angeles to Las Vegas</h2>
                    <p>Discovering economical flights from Los Angeles to Las Vegas is achievable with the right approach. By considering key factors such as timing and booking strategies as presented by Fareslist, you can secure great deals for your journey. Here are some quick tips:</p>
                    <div class="d-lg-flex">
                        <ul>
                            <li>Choose January travel.</li>
                            <li>Avoid March flights.</li>
                            <li>Fly on Saturdays.</li>
                            <li>Skip Fridays.</li>
                            
                        </ul>
                        <ul class="ms-lg-4 ">
                            <li>Opt for morning departures.</li>
                            <li>Book 30 days ahead.</li>
                            <li>Compare airline prices.</li>
                            <li>Consider Los Angeles - Las Vegas Harry Reid Intl.</li>
                        </ul>
                    </div>
                   
                </div>
                <div class="col-lg-6 rela">
                    <img class="orfw rela" src={LosAngeles} alt="" />
                    <img class="ihtf" src={LasVegas} alt="" />
                </div>
            </div>
        </div>
    </section>

    

    <section class="cvkfujg">
        <div class="container">
          <h2 class="text-center">Would You Like to Venture Varied Flight Options?</h2>
          <p class="text-center mb-4">Fareslist offers a range of flight fares from diverse airlines for your Los Angeles to Las Vegas trip, ensuring you find the most suitable and cost-effective options for your journey. </p>
          <div class="row">
            <div class="col-lg-12">
              <div class="slick-slider12 dtgd">
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={JetBlue} alt="" width="100%" />
                      <h6 class="sirnb"><i>JetBlue Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">LAX - LAS</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Spirit} alt="" width="100%" />
                      <h6 class="sirnb"><i>Spirit Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">LAX - LAS</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={United} alt="" width="100%" />
                      <h6 class="sirnb"><i>United Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">LAX - LAS</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={American} alt="" width="100%" />
                      <h6 class="sirnb"><i>American Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">LAX - LAS</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Delta} alt="" width="100%" />
                      <h6 class="sirnb"><i>Delta</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">LAX - LAS</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                
                
            </div>
        
            </div>
          </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={LasVegas2} alt="" width="100%" />
                </div>
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Fly to Las Vegas</i></p>
                    <h3>Reason of Flying from Los Angeles to Las Vegas is a Must-Do Experience</h3>
                    <p>Traveling from Los Angeles to Las Vegas offers an exciting blend of entertainment, vibrant nightlife, and world-class dining. Witness the thrill of the famous Strip, indulge in top-notch shows, and try your luck at the casinos for an unforgettable getaway.</p>
                </div>
                
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Best time to visit Vegas</i></p>
                    <h3>The Optimal Time to Visit Las Vegas for Budget-Friendly Travel and Exhilarating Events</h3>
                    <p>For affordable travel and thrilling events, consider visiting Las Vegas in the late spring or early fall. During these seasons, you'll find favorable weather, fewer crowds, and various festivals, making it an ideal time to explore the city without spending a fortune.</p>
                </div>
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={LasVegas3} alt="" width="100%" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
