import React from 'react';
import '../Resources/assest/css/style.css';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function BaggageFee() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    <Meta url={`${apiIP}/baggage-fee`} pageTitle="FareList - Airline Baggage Fees" pageDescription="Easily find all airline baggage fees with FareList. Stay informed about costs and avoid
surprises at the airport. Plan your travel budget effectively today!" />
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Baggage Fees</h2>
                <ul class="bred">
                    <li><a href="index.html">Home</a></li>
                    <li>Baggage Fees</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
    <section>
    <div class="container">
        <div class="table-responsive">
            <table >
                <thead>
                <tr>
                <th>Airlines</th>
                <th>CarryOn</th>
                <th>1st Bag</th>
                <th>2nd Bag</th>
                <th>Additional Policy</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="fl" height="25%" /> <a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">AirTran &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="as" height="25%" /> <a href="https://www.alaskaair.com/content/travel-info/baggage/checked-bags.aspx" rel="nofollow" target="_blank">Alaska Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/checked-bags.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/checked-bags.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/carry-on-luggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/overview.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="aa" height="25%" /> <a href="https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp#baggage" rel="nofollow" target="_blank">American Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.aa.com/i18n/travel-info/baggage/carry-on-baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.aa.com/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="ua" height="25%" /> <a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">United Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.united.com/CMS/en-US/travel/Pages/BaggageCarry-On.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="dl" height="25%" /> <a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">Delta Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage/during-your-trip/carry-on.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="f9" height="25%" /> <a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">Frontier &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/#info" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="ha" height="25%" /> <a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">Hawaiian Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.hawaiianairlines.com/bagfees#/Carryon" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="b6" height="25%" /> <a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">JetBlue Airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.jetblue.com/travel/baggage/#/carry-on-baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="yx" height="25%" /> <a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">Republic Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="sy" height="25%" /> <a href="https://www.suncountry.com/Fly/Baggage" rel="nofollow" target="_blank">Sun Country &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.suncountry.com/Fly/Baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.suncountry.com/Fly/Baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.suncountry.com/Fly/Baggage/Carry-On-Baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.suncountry.com/Fly/Baggage/Checked-Baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="nk" height="25%" /> <a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">Spirit Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="ws" height="25%" /> <a href="https://www.westjet.com/en-ca/travel-info/baggage/index" rel="nofollow" target="_blank">Westjet &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/index" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/index" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/carry-on" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/carry-on" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="us" height="25%" /> <a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">US airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.aa.com/i18n/travel-info/baggage/carry-on-baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="g4" height="25%" /> <a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">Allegiant Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="vx" height="25%" /> <a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">Virgin America &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="4b" height="25%" /> <a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">Boutique Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="9k" height="25%" /> <a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">Cape Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="m5" height="25%" /> <a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">Kenmore Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="mw" height="25%" /> <a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">Mokulele Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="7n" height="25%" /> <a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">PAWA Dominicana &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="ks" height="25%" /> <a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">PenAir &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="7h" height="25%" /> <a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">Ravn Alaska &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="3m" height="25%" /> <a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">Silver Airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="9x" height="25%" /> <a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">Southern Airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="o2" height="25%" /> <a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">Linear Air Taxi &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                                    <tr>
                                      <td class="rela futjg"><img class="backt" src="assest/img/FL.png" alt="wn" height="25%" /> <a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">Southwest Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                                      </tr>
                
                </tbody>
                </table>
        </div>
    </div>
</section>
    </>
  )
}
