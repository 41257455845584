import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/css/styles.css';
import Phone from '../Resources/assest/img/phone1.png';
import Email from '../Resources/assest/img/email.png';
import Contact from '../Resources/assest/img/contact.webp';
import User from '../Resources/assest/img/user.png';
import EmailS from '../Resources/assest/img/email2.png';
import LocationS from '../Resources/assest/img/map-location.png';
import SMS from '../Resources/assest/img/sms.png';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Contactus() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    <Meta url={`${apiIP}/contact-us`} pageTitle="FareList | Contact Us" pageDescription="Contact FareList for all your travel needs! Visit us at 5830 N Valentine Ave #102,
Fresno, CA 93711, USA, or call +1-866-660-6330 for assistance." />
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Contact Us</h2>
                <ul class="bred">
                    <li><Link to='/'>Home</Link></li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
    <section class="py-0">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-lg-3 rela dripd">
                  <a href="tel:+1-866-660-6330"> 
                     <div class="card awrwa edi text-center my-3 py-5">
                    <img class="mb-2 mx-auto" src={Phone} alt="" width="50px" />
                    <h5 class="mb-0"><b>Call us at</b></h5>
                    <h6>+1-866-660-6330</h6>
                   </div></a>
                       {/* <!-- <a href="tel:+1-866-660-6330">
                        <div class="fbd">
                          <div class="loader99o">
                            <div class="face99o">
                            <div class="circle99o"></div>
                            </div>
                            <div class="face99o">
                            <div class="circle99o"></div>
                            </div>
                            <div class="cir99o">
                                <img class="mb-2" src="assest/img/phone1.png " alt="">
                                <h5><b>Call us at</b></h5>
                                <h6>+1-866-660-6330</h6>
                            </div>
                        </div>
                         </div>
                       </a> --> */}
                </div>
                <div class="col-md-6 col-lg-3 rela">
                  <a href="mailto:contact@fareslist.com">
                  <div class="card awrwa edi text-center my-3 py-5">
                    <img class="mb-2 mx-auto" src={Email} alt="" width="50px" />
                    <h5 class="mb-0"><b>EMAIL</b></h5>
                    <h6>support@fareslist.com</h6>
                   </div></a>
                </div>
                <div class="col-md-6 col-lg-3 rela">
                  <a href="mailto:contact@fareslist.com">
                  <div class="card awrwa edi text-center my-3 py-5">
                    <img class="mb-2 mx-auto" src={LocationS} alt="" width="50px" />
                    <h5 class="mb-0"><b>Address</b></h5>
                    <h6>Suite: 403, Plot : A-10, Pegasus One, Sector-68, Noida (U.P) 201301</h6>
                   </div></a>
                </div>
                <div class="col-lg-3 d-flex align-items-center justify-content-center">
                    <img src={Contact} alt="" width="100%" />
                </div>
                
            </div>
        </div>
    </section>

    <section class=" back-fm-clr rela">
        <div class="container">
            <div class="row justify-content-center">
                {/* <div class="col-lg-5 mb-2">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.514892317404!2d-119.85637112504689!3d36.806179867364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8094663cb9bea9ed%3A0x28aab5da9e25b533!2s5830%20N%20Valentine%20Ave%20%23102%2C%20Fresno%2C%20CA%2093711%2C%20USA!5e0!3m2!1sen!2sin!4v1714650002021!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div> */}
                {/* <div class="col-lg-2">
                  <div class="d-flex justify-content-center">
                    <p class="partition mb-0"></p>
                  </div>
                </div> */}
                <div class="col-lg-6">
                    <div class="ubthop">
                        <h5 class="mb-3  text-center"><b>Got a question or a query? We've got you covered.</b></h5>
                        <form class="row g-3 needs-validation" novalidate>
                          <div class="col-md-12 rela">
                            {/* <!-- <label for="validationCustom01" class="form-label">Name</label> --> */}
                            <input type="text" class="form-control rft" id="validationCustom01" placeholder="Name" required />
                            <img class="form-ico1 ion-f" src={User} alt="" />
                            <div class="invalid-feedback">
                              Please Enter Your Name.
                            </div>
                          </div>
                          <div class="col-md-12 rela">
                            {/* <!-- <label for="validationCustom02" class="form-label">Email</label> --> */}
                            <input type="email" class="form-control rft" id="validationCustom02" placeholder="Email"  required />
                            <img class="form-ico1 ion-f" src={EmailS} alt="" />
                            <div class="invalid-feedback">
                              Please Enter Your Email.
                            </div>
                          </div>
                          
                          <div class="col-md-12 rela">
                            {/* <!-- <label for="validationCustom05" class="form-label">Phone Number</label> --> */}
                            <input type="number" class="form-control rft" id="validationCustom05" placeholder="Phone Number" required />
                            <img class="form-ico1 ion-f" src={Phone} alt="" />
                            <div class="invalid-feedback">
                              Please Enter Your Phone Number.
                            </div>
                          </div>
                          <div class="col-md-12 rela">
                            {/* <!-- <label for="validationCustom05" class="form-label">Message</label> --> */}
                            <input type="text" class="form-control rft" id="validationCustom05" placeholder="Message" required />
                            <img class="form-ico1 ion-f" src={SMS} alt="" />
                            <div class="invalid-feedback">
                              Please Enter Your Message.
                            </div>
                          </div>
                          
                          <div class="col-12">
                            <button class="btn btn-primary btn-clr-chng" type="submit">Submit form</button>
                          </div>
                        </form>
                        {/* <!-- <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" placeholder="Enter Your Name">
                        <label for="floatingInput">Your Name</label>
                        <img class="form-ico1" src="assest/img/user.png" alt="">
                      </div>
                      <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingEmail" placeholder="Enter Your Email">
                        <label for="floatingEmail">Email Address</label>
                        <img class="form-ico1" src="assest/img/email2.png" alt="">
                      </div>  
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingNumber" placeholder="Enter Your Number">
                        <label for="floatingNumber">Phone Number</label>
                        <img class="form-ico1" src="assest/img/phone.png" alt="">
                      </div>  
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingMessage" placeholder="Enter Your Message">
                        <label for="floatingMessage">Message</label>
                        <img class="form-ico1" src="assest/img/sms.png" alt="">
                      </div> 
                      <div class="col-md-12 text-center">
                        <button  type="submit" class="btn btn-primary btn-clr-chng">Search Flights</button>
                      </div> --> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="paper1"></div>
        
    </section>
    </>
  )
}
