import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import SearchEngine from '../Shared/SearchEngine';
import '../Resources/assest/css/style.css';

export default function City() {
  return (
    <>
    <SearchEngine />
    <section>
        <div class="container">
            <div class="row text-center">
                <h2>Lorem ipsum dolor sit, amet consectetur adipisicing elit!</h2>
                <p class="mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium voluptate obcaecati voluptates sit incidunt doloribus neque autem sed!</p>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Anchorage</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Juneau</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Fairbanks</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Ketchikan</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Sitka</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Haines</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Kodiak</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Kenia</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Nome</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Wasilla</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Petersburg</p>
                    </Link>
                </div>
                <div class="col-lg-3">
                    <Link to='/'>
                        <p class="iwnby">Seldovia</p>
                    </Link>
                </div>
                
            </div>
        </div>
    </section>
    </>
  )
}
