import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import discount from '../../Resources/img/discount.png';
import air from '../../Resources/img/air.png';
import logo from '../../Resources/img/logo.png';
import phone from '../../Resources/img/phone.png'

export default function Header() {
  const countryCode = useSelector((state) => state.countryCode.countryCode);
  console.log("11",countryCode)
  return (
    <header className="obrt ">
        <div className="container-fluid ">
            <div className="row ">
                <div className="col-md-8 col-lg-8 back-change d-flex align-items-center rela">
                    <marquee className="m-2 ms-5 "><img className="circular me-2" src={discount} alt="" width="3%" />Book affordable flight options from popular airlines for your favorite destinations.</marquee>
                </div>
                <div className="col-md-4 col-lg-4 rela ">
                  <div className="plan-fly">
                    <img className="plan-fly2"  src={air} alt=" " />
                  </div>
                  {/* <!-- <div className="plan-fly1">
                    <img className="plan-fly12"  src="assest/img/air.png" alt=" " />
                  </div> --> */}
                </div>
                
            </div>
        </div>
        <div className="container my-2">
          <div className="row d-flex align-items-center">
            <div className="col-5 col-md-3 col-lg-3">
            <NavLink className="navbar-brand me-0" to="/">
              <img className="logo-width" src={logo} alt="" /></NavLink>
            </div>
            <div className="col-2 col-md-6 col-lg-6">
              
              
               {countryCode !== "GB" ? (
                <>
                <div className="call d-flex align-items-center justify-content-center hide-mobile uft">
              <Link to="tel:+1-866-660-6330"><img className="icn-cl" src={phone} alt="" width="70%" /></Link>
              <Link to="tel:+1-866-660-6330"><div className="number">
                  <h4 className="mb-0">+1-866-660-6330</h4>
                  <p className="mb-0">Toll Free & 24/7 Available</p>
                </div></Link>
              </div>  
              <Link to="tel:+1-866-660-6330"> <img className="me-1 ms-auto mx-md-auto  d-none show-mobile icn-cl" src={phone} alt="" width="60%" /></Link>
              </>
            ) : (
              <>
                <div className="call d-flex align-items-center justify-content-center hide-mobile uft">
              <Link to="tel:+0203-869-5270"><img className="icn-cl" src={phone} alt="" width="70%" /></Link>
              <Link to="tel:+0203-869-5270"><div className="number">
                  <h4 className="mb-0">0203-869-5270</h4>
                  <p className="mb-0">Toll Free & 24/7 Available</p>
                </div></Link>
              </div>  
              <Link to="tel:+0203-869-5270"> <img className="me-1 ms-auto mx-md-auto  d-none show-mobile icn-cl" src={phone} alt="" width="60%" /></Link>
              </>
            )}
            </div>
            
            <div className="col-5 col-md-3 col-lg-3">
             <div className="d-flex justify-content-end">
              <div className="rela">
                <i className="fa fa-globe posi-ab " aria-hidden="true"></i>
              <select id="inputState" className="form-select  ">
                <option /*selected*/>English</option>
                {/* <!-- <option></option> --> */}
              </select>
              </div>
              

              {/* <!-- <a href=""> <button className="button12">
               <span className="text12">Login</span>
                <div className="wave12"></div>
            </button></a> --> */}
             </div>
            </div>
          </div>
        </div>

      {/* <!-- <div className="container">
            <div className="row">
                <div className="col-lg-12">
                  
                    <nav className="navbar navbar-expand-lg navbar-light bg-none">
                        <div className="container-fluid">
                         
                          <img className="me-1 ms-3  d-none show-mobile icn-cl" src="assest/img/phone.png" alt="" width="10%">
                          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                          </button>
                          <div className="collapse navbar-collapse ms-3" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                              <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="#"> <i className="fa fa-home" aria-hidden="true"></i> Home</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#"> <i className="fa fa-user-circle-o" aria-hidden="true"></i> About</a>
                              </li>
                             
                              <li className="nav-item">
                                <a className="nav-link" href="#"> <i className="fa fa-address-book-o" aria-hidden="true"></i> Contact</a>
                              </li>
                            </ul>
                            <div className="call d-flex align-items-center hide-mobile spac uft">
                              <img className="me-3 icn-cl" src="assest/img/phone.png" alt="" width="8%">
                              <div className="number">
                                <h4 className="mb-0">+1-844-414-9223</h4>
                                <p className="mb-0">Toll Free & 24/7 Available</p>
                              </div>
                            </div>
                            <div className="rela">
                              <i className="fa fa-globe posi-ab hide-mobile uft" aria-hidden="true"></i>
                            <select id="inputState" className="form-select  hide-mobile uft">
                              <option selected>English</option>
                              <option></option>
                            </select>
                            </div>
                            

                            <button className="button12">
                              <span className="text12">Login</span>
                              <div className="wave12"></div>
                          </button>
                          
                          </div>
                        </div>
                      </nav>
                </div>
            </div>
</div> --> */}
     

     </header>
  )
}
