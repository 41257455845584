import React, { useEffect } from 'react'
import '../Resources/assest/css/style.css'
import Seatle from '../Resources/assest/img/seattle.webp'
import Honolulu from '../Resources/assest/img/honolulu.webp'
import Hawaiian from '../Resources/assest/img/Hawaiian-Airlines.webp'
import United from '../Resources/assest/img/United-airlines.webp'
import Jetblue from '../Resources/assest/img/Jetblue-Airlines.webp'
import Alaska from '../Resources/assest/img/Alaska-Airlines.webp'
import Delta from '../Resources/assest/img/Delta.webp'
import Honolulu3 from '../Resources/assest/img/honolulu3.webp'
import Honolulu2 from '../Resources/assest/img/honolulu2.webp'
import $ from 'jquery';
import 'slick-carousel';
import SearchEngine from '../Shared/SearchEngine'

export default function SeatleHonolulu() {
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <SearchEngine />
    <section class="rughtd rela odhyr">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Flights from Seattle to Honolulu</i></p>
                    <h2>Cheap Flights from Seattle to Honolulu through Fareslist</h2>
                    <p>Scoring affordable flights from Seattle to Honolulu is achievable with the right planning. By choosing the optimal times and booking strategies, you can save a significant amount on your airfare. Here are some quick tips from Fareslist to help you find the best deals.</p>
                    <div class="d-lg-flex">
                        <ul>
                            <li>Fly in January.</li>
                            <li>Avoid December travel.</li>
                            <li>Book Wednesday flights.</li>
                            <li>Skip Saturday flights.</li>
                            
                        </ul>
                        <ul class="ms-lg-4 ">
                            <li>Choose noon departures.</li>
                            <li>Book 59 days ahead.</li>
                            <li>Compare airline prices.</li>
                            <li>Fly Seattle/Tacoma Intl to Honolulu.</li>
                        </ul>
                    </div>
                   
                </div>
                <div class="col-lg-6 rela">
                    <img class="orfw rela" src={Seatle} alt="" />
                    <img class="ihtf" src={Honolulu} alt="" />
                </div>
            </div>
        </div>
    </section>


    <section class="cvkfujg">
        <div class="container">
          <h2 class="text-center">Wanna Discover Various Flights?</h2>
          <p class="text-center mb-4">Fareslist offers a variety of flight options from multiple airlines for your Seattle to Honolulu trip, ensuring you find the most cost-effective and convenient fares for your journey. </p>
          <div class="row">
            <div class="col-lg-12">
              <div class="slick-slider12 dtgd">
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Hawaiian} alt="" width="100%" />
                      <h6 class="sirnb"><i>Hawaiian Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SEA - HNL</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={United} alt="" width="100%" />
                      <h6 class="sirnb"><i>United Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SEA - HNL</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Jetblue} alt="" width="100%" />
                      <h6 class="sirnb"><i>JetBlue Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SEA - HNL</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Alaska} alt="" width="100%" />
                      <h6 class="sirnb"><i>Alaska Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SEA - HNL</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Delta} alt="" width="100%" />
                      <h6 class="sirnb"><i>Delta</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SEA - HNL</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                
                
            </div>
        
            </div>
          </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={Honolulu3} alt="" width="100%" />
                </div>
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Why Honolulu?</i></p>
                    <h3>Why Flying from Seattle to Honolulu is a Life Changing Experience for Wanderlusts</h3>
                    <p>Flying from Seattle to Honolulu offers a tropical escape with stunning beaches, vibrant culture, and perfect weather. Experience the beauty of Hawaii’s landscapes, delicious cuisine, and rich traditions, making it an unforgettable destination. </p>
                </div>
                
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Optimal time to go to Honolulu</i></p>
                    <h3>Best Time to Visit Honolulu for Cheap Travel and Incredible Events</h3>
                    <p>For budget-friendly travel and vibrant events, plan your visit to Honolulu in the spring. March to May offers pleasant weather, fewer crowds, and various festivals, providing a perfect balance of savings and entertainment for your trip.</p>
                </div>
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={Honolulu2} alt="" width="100%" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
