import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/assest/css/style.css';
import flight from '../Resources/assest/img/flight.png';
import booking from '../Resources/assest/img/booking.png';
import clock from '../Resources/assest/img/clock.png';
import aboutus from '../Resources/assest/img/about-us.jpg';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Aboutus() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
     <Meta url={`${apiIP}/about-us`} pageTitle="FareList - About us" pageDescription="FareList is one of the best travel companies, offering exceptional service, diverse
travel options, and unbeatable deals. Experience unforgettable journeys with us!" />
    <div class="main-banner-im bred-ban">
    <section>
    <div class="container">
       <div class="row">
           <div class="col-lg-12 text-center">
               <h2 class="text-white mb-3">About Us</h2>
               <ul class="bred">
                   <li><Link to='/'>Home</Link></li>
                   <li>About Us</li>
               </ul>
           </div>
       </div>
    </div>
   </section>
   </div>
   <section>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <p class="mb-0"><i>Fly High. Pay Less.</i></p>
                <h2>Explore the World:<br/> Discover Fareslist</h2>
            </div>
            <div class="col-md-6 col-lg-3">
                <p>Set your sights on the horizon with Fareslist, immerse yourself in a realm of boundless opportunities as we reveal remarkable flight discounts and keep you up-to-date with the latest airline insights. </p>
            </div>
            <div class="col-md-6 col-lg-3">
                <p>Trust us to steer you through the skies, directing you towards the destinations that fuel your wanderlust with unparalleled ease and enthusiasm. Welcome to the adventure!</p>
            </div>
            <div class="col-md-6 col-lg-4 ">
                <div class="inrt d-flex align-items-center ">
                    <div class="gur">
                        <img src={flight} alt="" />
                    </div>
                    <div class="coodfgynt">
                        <p class="mb-0"><b>Select from all the renowned airlines </b></p>
                        <p class="mb-0">without much hassle. Find the best flight fare, and fly.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="inrt d-flex align-items-center ">
                    <div class="gur">
                        <img src={booking} alt="" />
                    </div>
                    <div class="coodfgynt">
                        <p class="mb-0"><b>Easy Booking with Fareslist.</b></p>
                        <p class="mb-0">With the most user-friendly interface, book reservations conveniently. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="inrt d-flex align-items-center ">
                    <div class="gur">
                        <img src={clock} alt="" />
                    </div>
                    <div class="coodfgynt">
                        <p class="mb-0"><b>Hold airfare for 24 hours. </b></p>
                        <p class="mb-0">We offer the option to hold your flight reservations for a day.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <img class="ubtddea mb-3" src={aboutus} alt="" width="100%" />
                </div>
                <div class="col-lg-6">
                    <p class="mb-0"><i>One Stop For All </i></p>
                    <h2>Fareslist is Your Ultimate Travel Companion, with a Commitment to Excellence</h2>
                    <p>At Fareslist, we pride ourselves on being your ultimate travel companion, offering a myriad of services to enhance your journey. Our dedicated team works tirelessly to provide you with the most accurate and up-to-date airline information, ensuring that you have all the details you need to plan your trip with confidence. With our 24/7 customer support, help is always just a click or call away. Whether you have questions about booking your flight or need assistance while traveling, our friendly and knowledgeable team is here to assist you every step of the way.</p>
                    <p>But that's not all. Fareslist also brings you exclusive, mind-blowing flight deals, discounts, and offers that you won't find anywhere else. From last-minute getaways to once-in-a-lifetime adventures, we're here to help you save money while making memories that last a lifetime.</p>
                    {/* <!-- <div class="d-flex align-items-center justify-content-between">
                        <img src="assest/img/offer.png" alt="">
                        <img src="assest/img/24-hours-support.png" alt="">
                        <img src="assest/img/cyber-security.png" alt="">
                        <img src="assest/img/offer.png" alt="">
                        <img src="assest/img/24-hours-support.png" alt="">
                    </div> --> */}
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="nosfth">
                        <h5>Venture Destinations</h5>
                        <p class="mb-0">Get the world's most amazing destinations with Fareslist, your ultimate gateway to adventure. From the bustling streets of Tokyo to the serene beaches of Bali, we curate a collection of breathtaking locales waiting to be explored. Whether you seek adrenaline-fueled escapades or tranquil retreats, we are your compass to unforgettable experiences. </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="nosfth">
                        <h5>Incredible Discounts</h5>
                        <p class="mb-0">Grab unbeatable savings with our incredible discounts and exclusive offers. From last-minute getaways to bucket-list adventures, we bring you mind-blowing deals that make every journey affordable and unforgettable. Trust Fareslist to be your passport to savings and start on your next adventure with confidence and excitement.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="nosfth">
                        <h5>Our Vision</h5>
                        <p class="mb-0">At Fareslist, our goals extend beyond providing exceptional travel services. We aim to redefine the way you experience the world, enriching your adventures with authenticity, accessibility, and unparalleled value. With our commitment to providing the best prices and value-added perks, you can explore the world, and create memories that last a lifetime. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
   </>
  )
}
