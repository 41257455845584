import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/assest/css/style.css'
import Australia from '../Resources/assest/img/Main/AustraliaCountries/Australia.webp'
import NewZeland from '../Resources/assest/img/Main/AustraliaCountries/New-Zealand.webp'
import Papua from '../Resources/assest/img/Main/AustraliaCountries/Papua-New-Guinea.webp'
import SearchEngine from '../Shared/SearchEngine';

export default function AustraliaContinent() {
  return (
    <>
    <SearchEngine />
    <section class="rela">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mb-3">
            <h2>Fly to the Land of Wonders, Fly to Australia!</h2>
            <p>Hop on an odyssey through the Australian Outback or dive into the technicolor splendor of the Great Barrier Reef. New Zealand's breathtaking scenery and Fiji's island paradises await discovery. </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Australia} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={NewZeland} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Papua} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
         
          
        </div>
      </div>
    </section>
    </>
  )
}
