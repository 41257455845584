import React, { useEffect } from 'react'
import '../Resources/assest/css/style.css'
import Miami from '../Resources/assest/img/miami.webp'
import Mexico from '../Resources/assest/img/mexico.webp'
import Aeromexico from '../Resources/assest/img/Aeromexico..webp'
import United from '../Resources/assest/img/United-airlines.webp'
import Spirit from '../Resources/assest/img/Spirit-Airlines.webp'
import American from '../Resources/assest/img/American-airlines.webp'
import Alaska from '../Resources/assest/img/Alaska-Airlines.webp'
import Mexico3 from '../Resources/assest/img/Mexico3.webp'
import Mexico2 from '../Resources/assest/img/mexico2.webp';
import $ from 'jquery';
import 'slick-carousel';
import SearchEngine from '../Shared/SearchEngine'

export default function MiamiMexico() {
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <SearchEngine />
    <section class="rughtd rela odhyr">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Flights from Miami to Mexico</i></p>
                    <h2>Budget-Friendly Flights from Miami to Mexico</h2>
                    <p>Discovering affordable flights from Miami to Mexico is easier than you think. By choosing the right travel times and booking strategies, you can save significantly on your journey. Here are some quick tips from Fareslist to help you find the best deals.</p>
                    <div class="d-lg-flex">
                        <ul>
                            <li>Fly midweek, like Wednesday.</li>
                            <li>Book 50 days ahead. </li>
                            <li>Choose afternoon flights.</li>
                            <li>Avoid flying in December.</li>
                            
                        </ul>
                        <ul class="ms-lg-4 ">
                            <li>Travel in February.</li>
                            <li>Use budget airlines.</li>
                            <li>Compare prices on our site.</li>
                            <li>Call us to get exclusive deals.</li>
                        </ul>
                    </div>
                   
                </div>
                <div class="col-lg-6 rela">
                    <img class="orfw rela" src={Miami} alt="" />
                    <img class="ihtf" src={Mexico} alt="" />
                </div>
            </div>
        </div>
    </section>

    

    <section class="cvkfujg">
        <div class="container">
          <h2 class="text-center">Want to Check More Great Flight Deals?</h2>
          <p class="text-center mb-4">Fareslist showcases a range of flight fares from various airlines for the Miami to Mexico route, helping you find the most cost-effective options for your travel needs.</p>
          <div class="row">
            <div class="col-lg-12">
              <div class="slick-slider12 dtgd">
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Aeromexico} alt="" width="100%" />
                      <h6 class="sirnb"><i>Aeromexico</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">MIA - MEX</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={United} alt="" width="100%" />
                      <h6 class="sirnb"><i>United Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">MIA - MEX</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Spirit} alt="" width="100%" />
                      <h6 class="sirnb"><i>Spirit Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">MIA - MEX</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={American} alt="" width="100%" />
                      <h6 class="sirnb"><i>American Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">MIA - MEX</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Alaska} alt="" width="100%" />
                      <h6 class="sirnb"><i>Alaska Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">MIA - MEX</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                
                
            </div>
        
            </div>
          </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={Mexico3} alt="" width="100%" />
                </div>
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Reasons to visit Mexico</i></p>
                    <h3>Why Flying from Miami to Mexico is a Must for Every Traveler</h3>
                    <p>Flying from Miami to Mexico offers a unique blend of vibrant culture, delicious cuisine, and stunning landscapes. Be it if you're exploring ancient ruins, relaxing on beautiful beaches, or indulging in local flavors, Mexico promises an unforgettable experience that you won’t want to miss.</p>
                </div>
                
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Optimal Time to Fly to Mexico</i></p>
                    <h3>Best Time to Visit Mexico for Affordable Travel and Exciting Events</h3>
                    <p>For budget-friendly travel and vibrant festivals, visit Mexico between January and March. This period offers lower airfare and accommodation costs while allowing you to experience lively events like Carnaval, making your trip both affordable and culturally enriching.</p>
                </div>
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={Mexico2} alt="" width="100%" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
