import logo from './logo.svg';
//import './App.css';
import { BrowserRouter } from 'react-router-dom'
import Layout from './Layout/Layout';
import './Resources/css/style.css';
import './Resources/css/responsive.css';
import './Resources/css/animation.css';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Layout />
    </div>
    </BrowserRouter>
  );
}

export default App;
