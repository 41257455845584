import React from 'react'
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import Email from '../../Resources/assest/img/email.png';
import pln from '../../Resources/assest/img/pln.png';
import footerbg from '../../Resources/assest/img/footer-bg.png'

export default function Footer() {
  return (
    <footer >
    <img className="pldn" src={pln} alt="" />
    <img className="reddf" src={footerbg} alt="" />
    <div className="paper"></div>
    <div className="container rela">
      <div className="row mt-5">
        <div className="col-md-6 col-lg-3">
          <h4>Quick Links</h4>
          <ul className="mt-3">
            <li><Link to='/about-us'>About Us</Link></li>
            <li><Link to='/'>Airline Baggage Fees</Link></li>
            <li><Link to ='/social'>Social Media</Link></li>
            <li><Link to='/contactus'>Contact Us</Link></li>
            <li><Link to='/sitemap'>Sitemap</Link></li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-3">
          <h4>Popular Airlines</h4>
          <ul className="mt-3">
            <li><Link to='/'>Lufthansa Airlines</Link></li>
            <li><Link to='/'>Allegiant Air</Link></li>
            <li><Link to='/'>JetBlue Airways</Link></li>
            <li><Link to='/'>Hawaiian Airlines</Link></li>
            <li><Link to='/'>Sun Country Airlines</Link></li>
            <li><Link to='/'>Frontier Airlines</Link></li>
            <li><Link to='/'>Spirit Airlines</Link></li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-3">
          <h4>Popular Destinations</h4>
          <ul className="mt-3">
            <li><Link to='/'>Atlanta</Link></li>
            <li><Link to='/'>Los Angeles</Link></li>
            <li><Link to='/'>Mexico City</Link></li>
            <li><Link to='/'>Miami</Link></li>
            <li><Link to='/'>New York</Link></li>
            <li><Link to='/'>Albuquerque</Link></li>
            <li><Link to='/'>Toronto</Link></li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-3 ">
          <h4>Subscribe</h4>
  
          <p className="mt-3 mb-2">Subscribe Our Newsletter For Getting Quick Updates</p>
          
         
          <form className=" my-1 fdyhdf ">
            <div className="form-floating ">
              <input type="text" className="form-control fgyf33 dgsdgvsd1" id="floatingInput" placeholder="Enter Your Name" />
              <label htmlFor="floatingInput">Your Email</label>
              <img className="form-ico1" src={Email} alt="" />
            </div>
            <button className="btn btn-primary fotr-bn mb-3 mt-2 px-4 py-2 dgsdgvsd fgyf133"  type="submit">Subscribe</button>
          </form>
          <p className="fornt">By subscribing, you consent to receive promotional emails including flight deals, discounts, and travel-related information.  for details, read our <Link to='/privacy-policy'>Privacy Policy</Link> and <Link to='/terms-and-conditions'>Term & Condition</Link></p>
          <div className=" addr">
             <div className="d-flex">
             <h6 className="mb-0 me-2">Address:</h6>
          {/* <ul className="mb-0">
            <li><Link to='/'>Suite: 403, Plot : A-10, Pegasus One, Sector-68, Noida (U.P) 201301</Link> </li>
          </ul> */}
             </div>
          <p className="kisairt mb-0">Fareslist.com is represented by TGS Ventures Pvt Ltd, which is registered in Suite: 403, Plot : A-10, Pegasus One, Sector-68, Noida (U.P) 201301, India</p>
          </div>
         
        </div>
        
        <div className="col-lg-12 d-lg-flex justify-content-between border-top text-center">
          <p className="mb-0 sfrwsr1">Copyrights © 2023-2024 fareslist.com, All Rights Reserved. </p>
          <ul className="sfrwsr1 mb-0">
              <li> <Link to='/disclaimer'>Disclaimer</Link>  |  <Link to='/privacy-policy'>Privacy Policy</Link>  |  <Link to='/terms-and-conditions'>Terms & Conditions</Link> </li>
            </ul>
        </div>
        
      </div>
    </div>
  </footer>
  )
}
