import React, { useEffect } from 'react';
import '../Resources/assest/css/style.css';
import flight from '../Resources/assest/img/flight.png';
import abt from '../Resources/assest/img/abt.jpg';
import fb from '../Resources/assest/img/fb.webp';
import air from '../Resources/assest/img/air.png';
import sec from '../Resources/assest/img/sec.png'
import SearchEngine from '../Shared/SearchEngine';
import $ from 'jquery';
import 'slick-carousel';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Airlines() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <Meta url={`${apiIP}/airlines`} pageTitle="Find Cheap Airline Tickets | Airfare & Discount Flights | FareList" pageDescription="Are you looking for cheap airline tickets? FareList offers discounted flights, last-
minute deals, and the best airfare prices for your next trip. Book now and save big!" />
    <div class="main-banner-im bred-ban"></div>
    <SearchEngine />
    <section class="rela outh etfstyj">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <p class="mb-0"><i>Lorem ipsum</i></p>
                    <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla cumque maiores ipsa eaque error, eos minus expedita maxime, nisi dignissimos, aperiam enim in deleniti libero amet. Dolorum, cum iure! Delectus.</p>
                    <div class="d-flex align-items-start">
                        <img src={flight} alt="" width="40px" />
                        <div class="cof ms-2">
                            <p class="mb-1"><b>Lorem ipsum dolor.</b></p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus odit quia, ipsum inventore facilis.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <img src={flight} alt="" width="40px" />
                        <div class="cof ms-2">
                            <p class="mb-1"><b>Lorem ipsum dolor.</b></p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus odit quia, ipsum inventore facilis.</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6">
                    <div class="ani-sec">
                        <img class="ani-sc-im" src={abt} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="cvkfujg">
      <div class="container">
        <h2 class="text-center">Lorem ipsum dolor sit amet consectetu.</h2>
        <p class="text-center mb-4">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores deserunt voluptate saepe vero, neque ut vel voluptas dicta, quaerat repellat illo doloremque et sequi! Suscipit blanditiis officiis harum distinctio perferendis.</p>
        <div class="row">
          <div class="col-lg-12">
            <div class="slick-slider12 dtgd">
              <div>
                <a href="">
                  <div class="slidrf rela mx-3">
                    <img src={fb} alt="" width="100%" />
                    <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                    <div class="package-ribbon-wrapper">
                      <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                      <div class="clear"></div>
                      <div class="package-type-gimmick"></div>
                      <div class="clear"></div>
                    </div>
                    <div class="destina">
                      <h6 class="mb-0">SFO - NYC</h6>
                      <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                    </div>
                  </div>
                  <p class="tijmh">  </p>
                </a>
              </div>
              <div>
                <a href="">
                  <div class="slidrf rela mx-3">
                    <img src={fb} alt="" width="100%" />
                    <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                    <div class="package-ribbon-wrapper">
                      <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                      <div class="clear"></div>
                      <div class="package-type-gimmick"></div>
                      <div class="clear"></div>
                    </div>
                    <div class="destina">
                      <h6 class="mb-0">SFO - NYC</h6>
                      <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                    </div>
                  </div>
                  <p class="tijmh">  </p>
                </a>
              </div>
              <div>
                <a href="">
                  <div class="slidrf rela mx-3">
                    <img src={fb} alt="" width="100%" />
                    <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                    <div class="package-ribbon-wrapper">
                      <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                      <div class="clear"></div>
                      <div class="package-type-gimmick"></div>
                      <div class="clear"></div>
                    </div>
                    <div class="destina">
                      <h6 class="mb-0">SFO - NYC</h6>
                      <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                    </div>
                  </div>
                  <p class="tijmh">  </p>
                </a>
              </div>
              <div>
                <a href="">
                  <div class="slidrf rela mx-3">
                    <img src={fb} alt="" width="100%" />
                    <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                    <div class="package-ribbon-wrapper">
                      <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                      <div class="clear"></div>
                      <div class="package-type-gimmick"></div>
                      <div class="clear"></div>
                    </div>
                    <div class="destina">
                      <h6 class="mb-0">SFO - NYC</h6>
                      <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                    </div>
                  </div>
                  <p class="tijmh">  </p>
                </a>
              </div>
              <div>
                <a href="">
                  <div class="slidrf rela mx-3">
                    <img src={fb} alt="" width="100%" />
                    <h6 class="sirnb"><i>QATAR AIRWAYS</i></h6>
                    <div class="package-ribbon-wrapper">
                      <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                      <div class="clear"></div>
                      <div class="package-type-gimmick"></div>
                      <div class="clear"></div>
                    </div>
                    <div class="destina">
                      <h6 class="mb-0">SFO - NYC</h6>
                      <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                    </div>
                  </div>
                  <p class="tijmh">  </p>
                </a>
              </div>
              
              
          </div>
      
          </div>
        </div>
      </div>
  </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <p class="mb-0 "><i>Lorem ipsum</i></p>
            <h3 class=" mb-4 ">Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
          </div>
          <div class="col-lg-6">
            <p class="mb-4  fy6ns">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis blanditiis saepe maiores quas perspiciatis ipsam? Nihil sapiente fuga sit quae magni voluptas enim pariatur.</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="inner-card ry6hvg">
              <div class="ftg">
                <img src={air} alt="" />
              </div>
              <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="inner-card ry6hvg">
              <div class="ftg">
                <img src={air} alt="" />      
              </div>
              <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="inner-card ry6hvg">
              <div class="ftg">
                <img src={air} alt="" />
              </div>
              <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="inner-card ry6hvg">
              <div class="ftg">
                <img src={air} alt="" />
              </div>
              <h5 >Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row align-items-center">
          <p class="mb-0 text-center"><i>Lorem ipsum</i></p>
          <h3 class="text-center mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. </h3>
          <div class="col-lg-4">
            <div class="inner-card d-flex align-items-start mb-4 rela saedrt">
              <div class="ftg me-3 dtgtth">
                <img src={air} alt="" />
              </div>
              <div class="vunfg">
                <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
              </div>
            </div>
            <div class="inner-card d-flex align-items-start rela saedrt">
              <div class="ftg me-3 dtgtth">
                <img src={air} alt="" />
              </div>
              <div class="vunfg">
                <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <img class="redfg" src={sec} alt="" width="100%" />
          </div>
          <div class="col-lg-4">
            <div class="inner-card d-flex align-items-start mb-4 rela saedrt">
              <div class="ftg me-3 dtgtth1">
                <img src={air} alt="" />
              </div>
              <div class="vunfg">
                <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
              </div>
            </div>
            <div class="inner-card d-flex align-items-start rela saedrt">
              <div class="ftg me-3 dtgtth1">
                <img src={air} alt="" />
              </div>
              <div class="vunfg">
                <h5>Lorem ipsum dolor.</h5>
              <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga perspiciatis enim dolor consequuntur id cumque.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="d-lg-flex align-items-center shadow p-3 rounded ujhyt">
              <img src={abt} alt="" width="100%" height="150px" />
              <div class="firy ps-3">
                <h6>Lorem ipsum dolor.</h6>
                <p class=" mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ex accusamus at, nihil, sapiente totam libero provident, numquam autem voluptates unde ducimus aliquam veniam molestiae. Laborum repellat provident perferendis laboriosam!</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="d-lg-flex align-items-center shadow p-3 rounded ujhyt">
              <img src={abt} alt="" width="100%" height="150px" />
              <div class="firy ps-3">
                <h6>Lorem ipsum dolor.</h6>
                <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ex accusamus at, nihil, sapiente totam libero provident, numquam autem voluptates unde ducimus aliquam veniam molestiae. Laborum repellat provident perferendis laboriosam!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
