import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import countryCodeReducer from '../reducers/inedx';

const rootReducer = combineReducers({
  countryCode: countryCodeReducer,
});

const store = createStore(rootReducer);

export default store;
