import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/css/styles.css'

export default function Disclaimer() {
  return (
   <>
   <div class="main-banner-im bred-ban">
   <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Disclaimer</h2>
                <ul class="bred">
                  <li><Link to='/'>Home</Link></li>
                    <li>Disclaimer</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
   <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                  <div class="pr-pol">
        
                      
                    <p>Fareslist is your trusted flight-booking platform where you get exclusive flight deals and we would like you to request that you please take a moment to read and understand the following disclaimer, which outlines the terms and conditions governing the use of our website and the information provided herein.</p>
                     <p>At Fareslist, we strive to give all information, including flight details, prices, and promotions accurately and updated ones. We rely and relay on data sourced from reputable service providers and make continuous efforts to keep the content current. However, the travel industry is dynamic, and changes may occur without any prior notice.</p>               
                       <h5>Fareslist Disclaimer</h5>
                      <p>According to our Terms &amp; Conditions, Fareslist disclaims any liability for losses or damages resulting from relying on data available on our platform. This disclaimer extends to all of our content, including flight data, prices, and promotions, published on Fareslist.com and affiliated websites.</p>            
                       <p>The airfares, flight deals and offers presented on Fareslist are subject to various factors, including travel dates, booking periods, blackout dates, and terms and conditions specified by airline service providers. These factors may contribute to deals changing, and we reserve the right to modify offers without prior notice.</p>
                       <h5>Discount Policies:</h5>
                    <p>If you claim any discounts on Fareslist, please be very aware that we conduct thorough assessments to determine the validity of the discounts for your specific flight bookings. Our discount policies are influenced by various factors.</p>             
                      <p>By using our website Fareslist.com, you acknowledge and agree to the terms outlined on this disclaimer page. We recommend staying updated about the changes we make in our disclaimer for both parties involved. Your continued use of our platform will only substitute the acceptance of our terms and conditions herein.</p>
                        <p>Thank you for choosing Fareslist for your trips at affordable prices. We look forward to providing you with easy flight bookings.</p>
                        </div>
                   
                    <a id="button2424"></a>
                </div>
                
            </div>
        </div>
    </section>
   </>
  )
}
