import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/assest/css/style.css';
import France from '../Resources/assest/img/Main/EuropeCountries/France.webp';
import Germany from '../Resources/assest/img/Main/EuropeCountries/Germany.webp';
import Greece from '../Resources/assest/img/Main/EuropeCountries/Greece.webp';
import Italy from '../Resources/assest/img/Main/EuropeCountries/Italy.webp';
import Netherland from '../Resources/assest/img/Main/EuropeCountries/Netherlands.webp';
import Poland from '../Resources/assest/img/Main/EuropeCountries/Poland.webp';
import Switzerland from '../Resources/assest/img/Main/EuropeCountries/Switzerland.webp';
import UK from '../Resources/assest/img/Main/EuropeCountries/United-Kingdom.webp';
import Austria from '../Resources/assest/img/Small/EuropeCountries/Austria.webp';
import Belgium from '../Resources/assest/img/Small/EuropeCountries/Belgium.webp';
import Denmark from '../Resources/assest/img/Small/EuropeCountries/Denmark.webp';
import Ireland from '../Resources/assest/img/Small/EuropeCountries/Ireland.webp';
import Luxembourge from '../Resources/assest/img/Small/EuropeCountries/Luxembourg.webp';
import Romania from '../Resources/assest/img/Small/EuropeCountries/Romania.webp'
import SearchEngine from '../Shared/SearchEngine';

export default function EuropeContinent() {
  return (
    <>
    <SearchEngine />
    <section class="rela">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mb-3">
            <h2>Witness Chronicles of Culture in Europe</h2>
            <p>Europe: Wander through the historic streets of Rome or Paris, where every corner whispers tales of yesteryears. Prague's fairy-tale charm and the culinary delights of Spain and Italy await the curious traveler.</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={France} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Germany} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Greece} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Italy} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Netherland} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Poland} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Switzerland} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={UK} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          
        </div>
      </div>
    </section>

    <section class="cvkfujg">
      <div class="container text-center">
        <h2>Exploring Beyond the Mainstream of Europe</h2>
            <p class="mb-4">Venture beyond the tourist trail and uncover Europe's hidden treasures, from the fairy-tale charm of Slovenia to the medieval beauty of Estonia through Fareslist. Discover the untamed landscapes of Iceland or immerse yourself in Bosnia and Herzegovina.</p>
        <div class="row justify-content-center">
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Austria} alt="" width="100%" />
              <p class="mt-2"><b>Austria</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Belgium} alt="" width="100%" />
              <p class="mt-2"><b>Belgium</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Denmark} alt="" width="100%" />
              <p class="mt-2"><b>Denmark</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Ireland} alt="" width="100%" />
              <p class="mt-2"><b>Ireland</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Luxembourge} alt="" width="100%" />
              <p class="mt-2"><b>Luxembourg</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Romania} alt="" width="100%" />
              <p class="mt-2"><b>Romania</b></p>
            </div>
            </Link>
          </div>

          <div class="mt-4 ">
            <Link yo='/sitemap'>
              <button class="btnff"><i class="animationff"></i>View More<i class="animationff"></i>
            </button>
          </Link>
        </div>
         
        </div>
      </div>
    </section>
    
    
    <a id="button2424"></a>
    </>
  )
}
