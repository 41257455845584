import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import notFound from '../Resources/assest/img/404 Error.jpg'

export default function NotFound() {
  return (
    <>
    <section class="eror">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-12">
            <img src={notFound} alt="" width="100%" />
            <Link to='/'>
              <button class="btnff"><i class="animationff"></i>Back to Home<i class="animationff"></i>
            </button>
          </Link>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
