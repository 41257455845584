import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/css/styles.css';
import first from '../Resources/assest/img/1233.webp';
import fb from '../Resources/assest/img/fb.webp';
import rt from '../Resources/assest/img/rt.png';
import insta from '../Resources/assest/img/insta.webp';
import twitter from '../Resources/assest/img/twitter.webp';
import pinterest from '../Resources/assest/img/pinterest.webp';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Social() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    <Meta url={`${apiIP}/social`} pageTitle="FareList | Stay Connected for Travel Deals and Updates" pageDescription="Stay connected with FareList for the latest travel deals, updates, and tips! Follow us
for exclusive offers and inspiration for your next adventure." />
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Farelist On Social Network Sites </h2>
                <ul class="bred">
                  <li><Link to='/'>Home</Link></li>
                    <li>Social</li>
                </ul>
            </div>
        </div>
     </div>
    </section>    
    </div>
    <section class="rela odhyr">
      <img class="posir spin2323" src={first} alt="" />
      <div class="container">
        <div class="row justify-content-center text-center">
          <h2>Connect with us in our Socials, y’all!</h2>
            <p>Reach out to us in our venture to network in all of the leading social media platforms!</p>
          <div class="col-md-6 col-lg-3 text-start my-2">
            <div class="solic rela">
              <img class="rujt" src={fb} alt="" width="100%" />
              <div class="contefrt">
                <div class="d-flex align-items-center justify-content-center mr-topp">
                  {/* <!-- <p class="hefg mb-0"><b>FACEBOOK</b></p>  --> */}
                  <div class="social1 me-2">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </div>
                </div>
                
              <p>We are here to help you recall your best times and create new unforgettable moments! Follow our FB page for a daily dose of inspiration, travel tips, and exclusive deals.</p>
              <a href="https://www.facebook.com/fareslistus/" target="_blank" class="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img class="arrow-right1" src={rt} alt=""/></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 text-start my-2">
            <div class="solic rela">
              <img class="rujt" src={insta} alt="" width="100%" />
              <div class="contefrt">
                <div class="d-flex align-items-center justify-content-center mr-topp">
                  {/* <!-- <p class="hefg mb-0"><b>INSTAGRAM</b></p>  --> */}
                  <div class="social1 me-2">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </div>
                </div>
              <p>Connect with us on Insta for recent updates on airline industry and even get all the latest & exclusive flight deals from our company! While scrolling reels and memes, don't miss out on airline deets.</p>
              <a href="https://www.instagram.com/fareslist/" target="_blank" class="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img class="arrow-right1" src={rt} alt=""/></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 text-start my-2">
            <div class="solic rela">
              <img class="rujt" src={twitter} alt="" width="100%"/>
              <div class="contefrt">
                <div class="d-flex align-items-center justify-content-center mr-topp">
                  {/* <!-- <p class="hefg mb-0"><b>TWITTER</b></p>  --> */}
                  <div class="social1 me-2">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </div>
                </div>
              <p>Looking for instant answers or the latest buzz from Fareslist? Follow us for latest tweets from airline, where your queries get quick answers and updates are just a tweet away.</p>
              <a href="https://twitter.com/Fareslistus" target="_blank" class="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img class="arrow-right1" src={rt} alt=""/></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 text-start my-2">
            <div class="solic rela">
              <img class="rujt" src={pinterest} alt="" width="100%"/>
              <div class="contefrt">
                <div class="d-flex align-items-center justify-content-center mr-topp">
                  {/* <!-- <p class="hefg mb-0"><b>PINTEREST</b></p>  --> */}
                  <div class="social1 me-2">
                    <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                  </div>
                </div>
              <p>Stay in the loop with the latest news and updates! From exciting promotions to insider tips on travel destinations, our company pins you about airlines in every step of the way. </p>
              <a href="https://www.pinterest.com/fareslist/" target="_blank" class="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img class="arrow-right1" src={rt} alt=""/></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 text-start my-2">
            <div class="solic rela">
              <img class="rujt" src={fb} alt="" width="100%"/>
              <div class="contefrt">
                <div class="d-flex align-items-center justify-content-center mr-topp">
                  {/* <!-- <p class="hefg mb-0"><b>YOU TUBE</b></p>  --> */}
                  <div class="social1 me-2">
                    <i class="fa fa-youtube-play" aria-hidden="true"></i>
                  </div>
                </div>
              <p>Our InShorts and videos aim to keep you informed you on airline updates, latest blogs on flight discounts and exclusive deals on all routes. We aspire to give you the liberty of traveling with no bounds.</p>
              <a href="https://www.pinterest.com/fareslist/" target="_blank" class="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img class="arrow-right1" src={rt} alt=""/></a>
              </div>
            </div>
          </div>

          

          
        </div>
       

      </div>
      
    </section>
    </>
  )
}
