import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import first from '../Resources/assest/img/1233.webp';
import '../Resources/assest/css/style.css';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Sitemap() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    <Meta url={`${apiIP}/sitemap`} pageTitle="Sitemap | FareList" pageDescription="Sitemap, FareList - Navigate Our Site for Easy Access to Travel Deals and Information!" />
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Sitemap</h2>
                <ul class="bred">
                    <li><Link to='/'>Home</Link></li>
                    <li>Sitemap</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
    <section class="rela">
    <img class="posir spin2323" src={first} alt="" />
    <div class="container fgdgrr">
        
        <div class="row cvkfujg p-3 shadow rounded mb-3">
            <h4 class="my-3 edryte">Top Airlines</h4>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Lufthansa Airlines </Link></li>
                    <li><Link to='/'>Allegiant Air</Link></li>
                    <li><Link to='/'>JetBlue Airways</Link></li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Hawaiian Airlines</Link></li>
                    <li><Link to='/'>Sun Country Airlines</Link></li>
                    <li><Link to='/'>Frontier Airlines</Link></li>
                </ul>
            </div>
        </div>
        <div class="row cvkfujg p-3 shadow rounded mb-3">
            <h4 class="my-3 edryte">Popular Destinations </h4>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Atlanta</Link></li>
                    <li><Link to='/'>Los Angeles</Link></li>
                    <li><Link to='/'>Mexico City</Link></li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Miami</Link></li>
                    <li><Link to='/'>New York</Link></li>
                    <li><Link to='/'>Albuquerque</Link></li>
                </ul>
            </div>
        </div>
        <div class="row cvkfujg p-3 shadow rounded mb-3">
            <h4 class="my-3 edryte">Popular Deals</h4>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Business Class Flight Deals</Link></li>
                    <li><Link to='/'>International Flight Deals</Link></li>
                    <li><Link to='/'>Military Flight Travel Deals</Link></li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Domestic Flight Deals</Link></li>
                    <li><Link to='/'>LGBT Travel Deals</Link></li>
                    <li><Link to='/'>Mothers Day Flight Deals</Link></li>
                </ul>
            </div>
            {/* <a href="" class="text-end"><p class="mb-0"><b>view more</b></p></a> */}
        </div>
        <div class="row cvkfujg p-3 shadow rounded mb-3">
            <h4 class="my-3 edryte">Popular Routes </h4>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Atlanta To Miami </Link></li>
                    <li><Link to='/'>Los Angeles To Las Vegas</Link></li>
                    <li><Link to='/'>Mexico City To Denver</Link></li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li><Link to='/'>Miami To New York</Link></li>
                    <li><Link to='/'>New York To San Francisco</Link></li>
                    <li><Link to='/'>Albuquerque To Charlotte</Link></li>
                </ul>
            </div>
        </div>
        
        <div class="row cvkfujg p-3 shadow rounded mb-3">
          <h4 class=" my-3 edryte">Continents</h4>
          <div class="col-lg-6">
              <ul>
                  <li><Link to='/north-america'>North-america</Link></li>
                  <li><Link to='/africa'>Africa</Link></li>
                  <li><Link to='/australia'>Australia</Link></li>
                  
              </ul>
          </div>
          <div class="col-lg-6">
              <ul>
                <li><Link to='/south-america'>South-america</Link></li>
                  <li><Link to='/europe'>Europe</Link></li>
                  <li><Link to='/asia'>Asia</Link></li>
              </ul>
          </div>
      </div>
      <div class="row cvkfujg p-3 shadow rounded mb-3">
        <h4 class=" my-3 edryte">Countries</h4>
        <div class="col-lg-6">
            <ul>
                <li><Link to='/'>North-america</Link></li>
                <li><Link to='/'>Africa</Link></li>
                <li><Link to='/'>Australia</Link></li>
                
            </ul>
        </div>
        <div class="col-lg-6">
            <ul>
              <li><Link to='/'>South-america</Link></li>
                <li><Link to='/'>Europe</Link></li>
                <li><Link to='/'>Asia</Link></li>
            </ul>
        </div>
    </div>
    <div class="row cvkfujg p-3 shadow rounded mb-3">
      <h4 class=" my-3 edryte">States</h4>
      <div class="col-lg-6">
          <ul>
              <li><Link to='/'>North-america</Link></li>
              <li><Link to='/'>Africa</Link></li>
              <li><Link to='/'>Australia</Link></li>
              
          </ul>
      </div>
      <div class="col-lg-6">
          <ul>
            <li><Link to='/'>South-america</Link></li>
              <li><Link to='/'>Europe</Link></li>
              <li><Link to='/'>Asia</Link></li>
          </ul>
      </div>
  </div>
      <div class="row cvkfujg p-3 shadow rounded mb-3">
        <h4 class=" my-3 edryte">Traveler Assistance</h4>
        <div class="col-lg-6">
            <ul>
                <li><Link to='/about-us'>About Us</Link></li>
                <li><Link to='/baggage-fee'>Airline Baggage Fees</Link></li>
                <li><Link to='/terms-and-conditions'>Terms & Conditions</Link></li>
                <li><Link to='/social'>Social Media</Link></li>
            </ul>
        </div>
        <div class="col-lg-6">
            <ul>
                <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                <li><Link to='/disclaimer'>Disclaimer</Link></li>
                <li><Link to='/contactus'>Contact Us</Link></li>
            </ul>
        </div>
    </div>
    </div>
</section>
    </>
  )
}
