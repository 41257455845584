import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import Routers from '../Routes/Routers'
import '../Resources/assest/css/responsive.css'


export default function Layout() {
  return (
    <div>
      <Header />
      <Routers />
      <Footer />
    </div>
  )
}
