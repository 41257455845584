import React from 'react'
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/assest/css/style.css'
import US from '../Resources/assest/img/Main/NorthAmericaCountries/United-States.webp'
import Canada from '../Resources/assest/img/Main/NorthAmericaCountries/Canada.webp'
import Mexico from '../Resources/assest/img/Main/NorthAmericaCountries/Mexico.webp'
import Panama from '../Resources/assest/img/Main/NorthAmericaCountries/Panama.webp'
import CostaRica from '../Resources/assest/img/Main/NorthAmericaCountries/Costa-Rica.webp'
import Bahamas from '../Resources/assest/img/Main/NorthAmericaCountries/The-Bahamas.webp'
import ElSelvador from '../Resources/assest/img/Main/NorthAmericaCountries/EL-Salvador.webp'
import Belize from '../Resources/assest/img/Main/NorthAmericaCountries/Belize.webp'
import Angulia from '../Resources/assest/img/Small/NorthAmericaCountries/Anguilla.webp'
import Barbados from '../Resources/assest/img/Small/NorthAmericaCountries/Barbados.webp'
import Dominica from '../Resources/assest/img/Small/NorthAmericaCountries/Dominica.webp'
import Grenada from '../Resources/assest/img/Small/NorthAmericaCountries/Grenada.webp'
import SaintLuicia from '../Resources/assest/img/Small/NorthAmericaCountries/Saint-Lucia.webp'
import SaintMartin from '../Resources/assest/img/Small/NorthAmericaCountries/Saint-Martin.webp'
import SearchEngine from '../Shared/SearchEngine';

export default function NorthAmerica() {
  return (
    <>
    <SearchEngine />
    <section class="rela">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mb-3">
            <h2>North America - Exploring the Continent's Heartbeat</h2>
            <p>Experience the kaleidoscope of cultures in the United States, or embrace Canada's wild expanses. Mexico's rich heritage and the Caribbean's azure waters offer a symphony of adventure and relaxation through Fareslist. </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={US} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Canada} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Mexico} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Panama} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={CostaRica} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Bahamas} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={ElSelvador} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Belize} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          
        </div>
      </div>
    </section>

    <section class="cvkfujg">
      <div class="container text-center">
        <h2>North America's Best-Kept Secrets: Offbeat Escapes Across its lands</h2>
            <p class="mb-4">Escape the crowds and delve into North America's hidden treasures, from the pristine wilderness of Alaska to the colorful charm of Guatemala. Dive into Belize's underwater wonders or explore the cultural melting pot of Trinidad and Tobago.</p>
        <div class="row justify-content-center">
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Angulia} alt="" width="100%" />
              <p class="mt-2"><b>New York</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Barbados} alt="" width="100%" />
              <p class="mt-2"><b>Argentina</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Dominica} alt="" width="100%" />
              <p class="mt-2"><b>Amsterdam</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Grenada} alt="" width="100%" />
              <p class="mt-2"><b>Barcelona</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={SaintLuicia} alt="" width="100%" />
              <p class="mt-2"><b>Dubai</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={SaintMartin} alt="" width="100%" />
              <p class="mt-2"><b>Bangkok</b></p>
            </div>
            </Link>
          </div>

          <div class="mt-4 ">
            <Link to='/sitemap'>
              <button class="btnff"><i class="animationff"></i>View More<i class="animationff"></i>
            </button>
          </Link>
        </div>
         
        </div>
      </div>
    </section>
    </>
  )
}
