import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import China from '../Resources/assest/img/Main/AsiaCountries/China.webp';
import Japan from '../Resources/assest/img/Main/AsiaCountries/Japan.webp';
import Indonesia from '../Resources/assest/img/Main/AsiaCountries/Indonesia.webp';
import INDIA from '../Resources/assest/img/Main/AsiaCountries/India.webp';
import Pakistan from '../Resources/assest/img/Main/AsiaCountries/Pakistan.webp';
import Thailand from '../Resources/assest/img/Main/AsiaCountries/Thailand.webp';
import Singapore from '../Resources/assest/img/Main/AsiaCountries/Singapore.webp';
import Phillipines from '../Resources/assest/img/Main/AsiaCountries/Philippines.webp';
import Armenia from '../Resources/assest/img/Small/AsiaCountries/Armenia.webp';
import UAE from '../Resources/assest/img/Small/AsiaCountries/United-Arab-Emirates.webp';
import Oman from '../Resources/assest/img/Small/AsiaCountries/Oman.webp';
import behrain from '../Resources/assest/img/Small/AsiaCountries/Bahrain.webp';
import Qatar from '../Resources/assest/img/Small/AsiaCountries/Qatar.webp';
import Saudi from '../Resources/assest/img/Small/AsiaCountries/Saudi-Arabia.webp'
import SearchEngine from '../Shared/SearchEngine';

export default function AsiaContinent() {
  return (
    <>
    <SearchEngine />
    <section class="rela">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mb-3">
            <h2>Fly to Asia to Get Treasures of the East</h2>
            <p>Delve into the vibrant culture of Japan's modern marvels or find serenity amidst India's sacred sites. From the majestic Great Wall of China to Thailand's sun-kissed shores, Asia beckons with boundless wonders. </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={China} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Japan} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Indonesia} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={INDIA} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Pakistan} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Thailand} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Singapore} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Phillipines} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          
        </div>
      </div>
    </section>

    <section class="cvkfujg">
      <div class="container text-center">
        <h2>Asia's Uncharted Territories: Discovering Hidden Treasures</h2>
            <p class="mb-4">Venture off the beaten path to uncover Asia's hidden gems, where Bhutan's serene landscapes and Laos' ancient temples await. From the rugged beauty of Mongolia to the cultural richness of Uzbekistan, there's magic waiting to be discovered.</p>
        <div class="row justify-content-center">
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Armenia} alt="" width="100%" />
              <p class="mt-2"><b>Armenia</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={UAE} alt="" width="100%" />
              <p class="mt-2"><b>United Arab Emirates</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Oman} alt="" width="100%" />
              <p class="mt-2"><b>Oman</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={behrain} alt="" width="100%" />
              <p class="mt-2"><b>Bahrain</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Qatar} alt="" width="100%" />
              <p class="mt-2"><b>Qatar</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Saudi} alt="" width="100%" />
              <p class="mt-2"><b>Saudi Arabia</b></p>
            </div>
            </Link>
          </div>

          <div class="mt-4 ">
            <Link to='/sitemap'>
              <button class="btnff"><i class="animationff"></i>View More<i class="animationff"></i>
            </button>
          </Link>
        </div>
         
        </div>
      </div>
    </section>
    </>
  )
}
