import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/css/styles.css';

export default function Privacy() {
  return (
    <>
    <div class="main-banner-im bred-ban">
    <section>
     <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white mb-3">Privacy Policy</h2>
                <ul class="bred">
                  <li><Link to='/'>Home</Link></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
     </div>
    </section>
    </div>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ding d-flex">
                        <ul class="linfg">
                            <li><a href="#hedthj1">Data We Gather Concerning You and Its Method of Acquisition</a></li>
                            <li><a href="#hedthj2">Data Gathering Process</a></li>
                            <li><a href="#hedthj3">Data You Furnish to Us</a></li>
                            <li><a href="#hedthj4">Your Voluntary Contributions and Website Usage</a></li>
                            <li><a href="#hedthj5">Automatic Data Gathering Techniques and Your Online Interaction</a></li>
                            <li><a href="#hedthj6">Methods Employed for Automatic Data Collection</a></li>
                            <li><a href="#hedthj7">Cookie Usage and Its Varied Roles</a></li>
                            <li><a href="#hedthj8">Utilization of Cookies and Tracking Technologies by Third Parties</a></li>
                            <li><a href="#hedthj9">Utilization of Your Data</a></li>
                            <li><a href="#hedthj10">Revealing Your Information</a></li>
                        </ul>
                        <ul class="linfg">
                            
                            <li><a href="#hedthj11">Necessitated Personal Data Sharing for Legal Compliance</a></li>
                            <li><a href="#hedthj12">Exercising Influence Over Information Handling and Disclosure</a></li>
                            <li><a href="#hedthj13">Accessing and Rectifying Your Data</a></li>
                            <li><a href="#hedthj14">Ensuring Information Security</a></li>
                            <li><a href="#hedthj15">Preservation of Your Data</a></li>
                            <li><a href="#hedthj16">Handling Your Data</a></li>
                            <li><a href="#hedthj17">Global Data Movement</a></li>
                            <li><a href="#hedthj18">Notification of Rights for Individuals within the EU</a></li>
                            <li><a href="#hedthj19">Notice to Canadian Residents Regarding Your Rights</a></li>
                        </ul>
                        <ul class="linfg">
                            
                            <li><a href="#hedthj20">For your request to be valid:</a></li>
                            <li><a href="#hedthj21">Notice to Residents of California; California Consumer I Notification:</a></li>
                            <li><a href="#hedthj22">Notice to Residents of Nevada; Nevada Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj23">Notice to Residents of Virginia; Virginia Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj24">Notice to Residents of Colorado; Colorado Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj25">Notice to Residents of Connecticut; Connecticut Privacy Rights Notification:</a></li>
                            <li><a href="#hedthj26">Updates to Our Privacy Policy:</a></li>
                            <li><a href="#hedthj27">Contact Details:</a></li>
                        </ul>
                    </div>
                    <div class="pr-pol">
                        <p>At Fareslist, we hold utmost regard for your privacy and are dedicated to safeguarding it through our steadfast adherence to this protocol. This Privacy Declaration and Code of Conduct (hereinafter referred to as the "Guidelines") delineate the nature of data that might be gathered from you, or that you might furnish, whilst engaging with or utilizing fareslist, our Company's mobile site, and while accessing any material, features, or services provided through fareslist. Additionally, we present our methodology of amassing, utilizing, preserving, securing, revealing, and disseminating said data.</p>
                        <p>These Guidelines are pertinent to the data we procure under the following circumstances:</p>
                        <ul>
                            <li>When you navigate the Website, and/or employ our online services.</li>
                            <li>In electronic correspondence, textual exchanges, and other digital communications involving you and this Website.</li>
                            <li>During your interaction with our promotional content and utilities, which are accessible on third-party sites and platforms. This applies if these utilities or content incorporate hyperlinks to the present Guidelines.</li>
                        </ul>
                        <p>Conversely, these Guidelines do not encompass information acquired through the following channels:</p>
                        <ul>
                            <li>Offline avenues or any other mechanisms, including alternative websites managed by our Company or third parties.</li>
                            <li>Third-party sources, including applications or content (including adverts) that might be linked to or reached from the Website.</li>
                        </ul>
                        <p>We strongly encourage a meticulous perusal of these Guidelines to familiarize yourself with our policies and routines concerning your information, along with our intended treatment of said data. It's important to note that these Guidelines are subject to potential alterations (please refer to the "Modifications to Our Privacy Regulations" section). Thus, we recommend periodic visits to this document to remain informed about potential updates.</p>
                        <h6>Children Below the Age of 13</h6>
                        <p>We wish to emphasize that our Website is neither designed for, nor deliberately targeted at, nor intended to cater to individuals under the age of 13. We are committed to refraining from the conscious collection of personal data from children below the age of 13. If you are under the age of 13, we urge you to abstain from using or divulging any information on this Website, the Application, or any of its functionalities. This includes refraining from registering on the Website, conducting any transactions through the Website, engaging with any interactive or public comment elements on this Website, or sharing any form of personal data such as your name, address, contact number, email address, or any aliases or usernames you may employ.</p>
                        <p>Should we become aware of any instances where personal information has been unintentionally acquired from a child under the age of 13 without verified parental consent, we are fully committed to expunging said information from our records. If you suspect that we may possess information pertaining to or originating from an individual under the age of 13, we invite you to contact us at any time of day, as your concerns are of paramount importance to us.</p>
                        <h5 id="hedthj1">Data We Gather Concerning You and Its Method of Acquisition</h5>
                        <p>We engage in the collection of various forms of information from users of our Website. These encompass:</p>
                        <ul>
                            <li>Details that could potentially lead to your personal identification, encompassing elements such as your name, residential location, mailing address, email address, contact number, credit card data, payment details, or any other distinct markers that could be employed to contact you in either an online or offline context ("personal information").</li>
                            <li> Information that pertains to you yet does not singularly distinguish you.</li>
                            <li>Facts regarding your internet connectivity and IP address, the apparatus through which you gain entry to our Website, the category and dialect of your browser, access times and dates, incoming website URLs, and other usage particulars.</li>
                            <li>Categories of personal details described in segment (e) of Section 1798.80 of the California Civil Code. This includes items such as name, physical traits or depiction, address, phone number, as well as medical or health insurance details.</li>
                            <li>Attributes falling under safeguarded classifications according to state or federal law, like age, gender, marital status, medical status, and details about disabilities.</li>
                            <li>Data you provide on our Website, which aids in crafting travel plans, whether for yourself or another individual. This could involve elements such as your username, password, location, postal code, mailing address, email address, driver’s license, passport details, birth date, gender, arrival and departure data, and particulars related to travel-related programs (such as airline, hotel, or car rental loyalty programs).</li>
                            <li>Financial-related details, including the allocated budget for travel arrangements.</li>
                        </ul>
                        <h5 id="hedthj2">Data Gathering Process</h5>
                        <p>We amass this data through various channels:</p>
                        <ul>
                            <li>Direct Input: When you provide information to us, it's directly acquired.</li>
                            <li>Automated Tracking: As you explore the platform, certain details are automatically collected. These include usage patterns, IP addresses, operating systems, browser information, and data from cookies (compact text files saved in a user's web browser), as well as web beacons (digital visuals that tally users accessing specific content, including certain cookies). Additional tracking technologies also contribute to this automated collection.</li>
                            <li>Third-Party Involvement: Information can also be sourced from third parties, such as our collaborators in business ventures.</li>
                            <li>Social Media Platforms: If you use platforms like Google, Facebook, Twitter, Instagram, and others to connect with our Website or distribute information, data may be retrieved from these third-party social media services.</li>
                            <li>Unassociated Sources: External entities like analytics firms, advertising networks, affiliates, and other third parties may furnish us with data. This allows us to achieve a deeper comprehension of your preferences and present information that may resonate with you.</li>
                        </ul>
                        <h5 id="hedthj3">Data You Furnish to Us </h5>
                        <p>The data we accumulate via our Website encompasses:</p>
                        <ul>
                            <li>Form Entries: Details that you provide by completing forms on our Website. This encompasses data shared during the registration process for utilizing our Website, enrolling in our services, uploading content, or seeking additional services. Situations where you might report a Website-related issue might also involve us requesting specific information.</li>
                            <li>Communication Archives: Copies and records of your interactions, including email addresses, if you reach out to us.</li>
                            <li>Survey Feedback: Your inputs in surveys that we might solicit from you for research purposes.</li>
                            <li>Transaction Particulars: Information about transactions conducted on our Website, including the execution of travel bookings and purchases. Certain instances might necessitate the submission of financial details prior to finalizing a reservation via our Website.</li>
                            <li>Website Search Queries: The search terms you employ on the Website.</li>
                        </ul>
                        <h5 id="hedthj4">Your Voluntary Contributions and Website Usage</h5>
                        <p>It's within your purview to furnish information for potential publication, display, or transmission to fellow Website users or third parties (collectively referred to as "User Contributions"). Keep in mind that your User Contributions are shared, displayed, and transmitted at your own risk. While we institute access limitations for specific sections, it's essential to recognize that no security measures are entirely flawless or impervious. Furthermore, we lack authority over the actions of other Website users with whom you opt to share your User Contributions. Consequently, we are unable to offer an assurance that unauthorized individuals will not view your User Contributions.</p>
                        <p> In the process of downloading, accessing, and utilizing the website, subject to your device's settings, permissions, and your participation choices in particular programs, we may collect specific technical information from your device. This facilitates the proper functioning of the website and/or serves the other purposes outlined in this Policy. This includes:</p>
                        <ul>
                            <li>Usage Particulars: When you interact with and utilize the website, we might automatically gather specific aspects of your interactions and usage. This encompasses traffic statistics, location data, logs, communication data, and the resources accessed and utilized within the website.</li>
                            <li>Device Insights: We may compile data concerning your mobile device and internet connectivity. This encompasses your device's distinct identifier, IP address, operating system, version, browser type and version, mobile network particulars, as well as your device's contact number.</li>
                        </ul>
                        <h5 id="hedthj5">Automatic Data Gathering Techniques and Your Online Interaction</h5>
                        <p>During your engagement with our Website, we employ automatic data collection methods to amass particular details pertaining to your device, browsing activities, and trends. This includes:</p>
                        <ul>
                            <li>Visit Particulars: Information concerning your visits to our Website, incorporating traffic data, location insights, logs, communication data, and the resources you utilize on the Website.</li>
                            <li>Computer and Connectivity Data: Details about your computer and internet connection, which encompass your IP address, operating system, and browser type.</li>
                            <li>Usage Insights: Insights into your utilization of our Services, your interactions with our Services and advertisements, and data regarding uploaded data or files. This might encompass actions such as button clicks, mouse movements, keystrokes, page views, access dates and times, web beacons, and data from cookies or pixel tags. Further elaboration on cookies, pixel tags, web beacons, and analytic technologies is provided below.</li>
                        </ul>
                        <p>We may also leverage these technologies to gather information about your online endeavors across time and diverse third-party websites or online services (behavioral tracking). The automatically gathered data holds statistical significance and may include personal information. It can be linked with personal data obtained through alternative channels or received from third parties. To aid in monitoring and analyzing website activities, including but not restricted to Hotjar, Google Analytics & MixPanel, we may enlist third-party vendors. This practice facilitates the enhancement of our Website, enabling us to offer a more tailored and superior service by enabling us to:</p>
                        <ul>
                            <li>Assess Audience Size: Estimate the size of our audience and comprehend usage patterns.</li>
                            <li>Preferences Retention: Store your preferences, thus permitting the customization of our Website in line with your specific interests.</li>
                            <li>Enhanced Search Experience: Accelerate your search process.</li>
                            <li>Recognition Capability: Identify you upon your return to our Website.</li>
                        </ul>
                        <p>For the purpose of automatic data collection, we may utilize cookies and akin technology. Cookies are small text fragments sent to your computer or mobile device when you visit most websites. They can be classified as session cookies or persistent cookies. Session cookies facilitate the recognition and linkage of user actions within a single browsing session, terminating upon session conclusion. Persistent cookies help in recognizing existing users and remain on your device or system until expiry or deletion.</p>
                        <p>You hold the ability to manage your cookies by configuring or modifying your web browser controls to accept or reject cookies as desired. It's worth noting, however, that while refusing specific cookies is an option, it might limit your access to certain features and functionalities on our website. Certain cookies are indispensable or "essential" for the seamless operation of our site as intended. These essential cookies are indispensable for utilizing specific facets of our site, such as account access and reservation management. Opting out of or declining essential cookies is not a feasible choice.</p>
                        <h5 id="hedthj6">Methods Employed for Automatic Data Collection</h5>
                        <p>The strategies harnessed for this automated data acquisition encompass:</p>
                        <ul>
                            <li>Cookies (or Browser/Mobile Cookies): A cookie is a minor file positioned within your computer's hard drive. Activating the pertinent setting on your browser enables you to decline browser cookies. Nonetheless, opting for this preference might result in limited access to specific segments of our Website. Unless you configure your browser to reject cookies, our system will administer cookies when you navigate your browser to our Website.</li>
                            <li>Flash Cookies: Certain attributes of our Website might utilize locally stored objects (commonly referred to as Flash cookies) to amass and store your preferences and navigation patterns related to our Website. These Flash cookies do not fall under the purview of the same browser settings that regulate browser cookies.</li>
                            <li>Web Beacons: Our Website's pages and emails may incorporate minute electronic files recognized as web beacons (also known as clear gifs, pixel tags, and single-pixel gifs). These serve functions such as quantifying users who accessed specific pages or opened an email, alongside other correlated website statistics. For instance, they help gauge the popularity of specific content and ensure system and server integrity.</li>
                        </ul>
                        <h5 id="hedthj7">Cookie Usage and Its Varied Roles</h5>
                        <p>We employ cookies for numerous purposes, which encompass:</p>
                        <ul>
                            <li>Enhancing Visitor Experience: To enrich your experience during your interactions with our site.</li>
                            <li>Transaction Facilitation: For the smooth execution of transactions and ensuring our site operates as intended.</li>
                            <li>Preferences Retention: To consistently remember your preferences each time you revisit our site.</li>
                            <li>Relevant Advertising: To furnish you with pertinent advertisements and appraise the effectiveness of these advertisements.</li>
                            <li>Search History Preservation: To enable you to revisit and access previous searches conducted on our site.</li>
                            <li>Error Identification: To pinpoint and address errors within our site.</li>
                            <li>Data Safeguarding: To safeguard your data and potentially identify, investigate, and counter malicious and/or fraudulent activities.</li>
                            <li>Traffic and Performance Analysis: To comprehend site traffic and gauge the efficacy of our site's performance.</li>
                        </ul>
                        <h5 id="hedthj8">Utilization of Cookies and Tracking Technologies by Third Parties</h5>
                        <p>Several components, applications, and functionalities found on the Website, such as advertisements, ticket issuance, and payment processing, are operated by third parties. These include advertisers, ad networks and servers, content providers, analytics firms, payment processors, mobile device manufacturers, mobile service providers, and application providers. These third parties may choose to employ cookies on their own or in conjunction with web beacons or other tracking technologies to amass data about you when you utilize our website. The data they gather might either be linked to your personal information or they might collect data, including personal information, pertaining to your online undertakings spanning various websites and other online services. They could employ this data to offer interest-driven (behavioral) advertising or other personalized content.</p>
                        <p>The tracking technologies deployed by these third parties and their utilization are beyond our control. For inquiries pertaining to an advertisement or other customized content, you should directly communicate with the accountable provider.</p>
                        <h5 id="hedthj9">Utilization of Your Data</h5>
                        <p>The data collected from you or provided by you, including any personal details, serves the following purposes:</p>
                        <ul>
                            <li>Website Presentation: To showcase our Website and its contents for your perusal.</li>
                            <li>Reservation and Service Fulfillment: To execute your travel reservations, bookings, and purchases, communicate concerning these actions, and provide you with requested information, products, or services.</li>
                            <li>Adhering to Your Instructions: To cater to any other intent for which you've provided the information.</li>
                            <li>Contractual Obligations: To execute our responsibilities and enforce our rights stemming from any agreements between you and us. This includes payment processing, billing, and collection for contractually agreed amounts.</li>
                            <li>Notification of Changes: To inform you about modifications to our Website, App, products, or services we offer.</li>
                            <li>Promotion: To promote our products and services by transmitting marketing and advertising communications that we've devised and believe might intrigue you.</li>
                            <li>Account Maintenance: To establish, manage, and update user accounts on our Website and verify your identity as a user.</li>
                            <li>Interactive Engagement: To enable your participation in interactive aspects of our Website.</li>
                            <li>Support and Communication: To address your queries, information solicitations, and process data preferences.</li>
                            <li>Communication of Updates: To connect with you for purposes such as conveying travel booking confirmations and updates.</li>
                            <li>Adherence to Your Inputs: To align with any other purposes stated at the time of information submission.</li>
                            <li>Consented Use: For any additional purpose with your explicit consent.</li>
                            
                        </ul>
                        <p>The data we've gathered may be employed to reach out regarding goods and services that might be of interest to you. For guidance on opting out of information concerning goods and services, refer to "Preferences Regarding Our Use and Disclosure of Your Information" below.</p>
                        <p>We might use the collected information to display advertisements to target audiences for our advertisers. While we do not disclose personal data without consent for these purposes, if you interact with an advertisement, the advertiser might assume you meet their criteria.</p>
                        <h5 id="hedthj10">Revealing Your Information</h5>
                        <p>We may divulge collective insights about our user base and data that lacks the potential to identify specific individuals, all within the boundaries allowed by relevant legal statutes and regulations.</p>
                        <p>Personal data and information, as elucidated in this Policy, might be disclosed by us in the following scenarios:</p>
                        <ul>
                            <li>Affiliate Engagement: To our affiliated companies and subsidiaries.</li>
                            <li>Support Partnerships: To contractors, service providers, and other third parties essential for supporting our operations. This includes entities like Book Cheap Fare Flights Pvt. Ltd., solely as required for the execution of your travel arrangements. Often, the processing of personal data through a third party, such as airlines, hotel suppliers, car rental agencies, and visa/passport facilitators, is necessary to finalize your travel purchases.</li>
                            <li>Collaborative Endeavors: To other third-party collaborators in connection with our operations and the delivery of our services to you. This may encompass marketing agencies, campaign managers, IT developers, hosting service providers, promotional management entities, software providers, advertising networks, and site analytics providers.</li>
                            <li>Transition Situations: In the event of a merger, divestiture, restructuring, reorganization, dissolution, or the sale or transfer of some or all of the Company's assets, personal information related to our Website users might be among the assets transferred to a purchaser or successor.</li>
                            <li>Intended Use Fulfillment: To fulfill the specific purpose for which the data was provided.</li>
                            <li>Pre-disclosure Clarification: For any other objective communicated by us during the data provision process.</li>
                            <li>With Your Consent: With your explicit consent.</li>
                        </ul>
                        <h5 id="hedthj11">Necessitated Personal Data Sharing for Legal Compliance</h5>
                        <p>Furthermore, we retain the right to divulge your personal data to fulfill our lawful commitments:</p>
                        <ul>
                            <li>Court Orders and Legal Mandates: In order to adhere to any court directive, law, or legal proceedings, which encompass responding to governmental or regulatory requisitions.</li>
                            <li>Agreement Enforcement: To enforce or execute our terms of service and other contractual arrangements. This encompasses aspects such as billing and the collection process.</li>
                            <li>Safeguarding Interests: If, in good faith, we conclude that the revelation of data is requisite to guarantee your security or to safeguard the rights, assets, or welfare of Company, our clientele, or other entities. This encompasses information exchange with external firms and organizations to counteract fraud and mitigate credit risks. Please be assured that the provided content is both original and uniquely formulated.</li>
                        </ul>
                        <h5 id="hedthj12">Exercising Influence Over Information Handling and Disclosure</h5>
                        <p>We are committed to empowering you with choices regarding the handling of your personal data. Our mechanisms grant you control through the following avenues:</p>
                        <ul>
                            <li>Tracking Technologies and Advertising: Your browser settings can be configured to reject some or all browser cookies, including mobile cookies. You may also set it to alert you when cookies are being sent. For managing your Flash cookie settings, consult Adobe's website. However, it's essential to acknowledge that disabling or rejecting cookies could lead to certain segments of the site becoming inaccessible or functioning improperly.</li>
                            <li>Personal Data Disclosure for Third-Party Advertising: If you prefer that we do not share your personal data with external or non-representative third parties for promotional purposes.</li>
                            <li>Company-Originated Promotions: If you wish to avoid the use of your contact details by the Company to endorse our products or services, or those of third parties, you can opt-out by contacting us. Alternatively, if you have received a promotional email from us, sending a return email to be excluded from future distributions is a viable option. However, please be informed that this opt-out doesn't pertain to data furnished to the Company through product purchases, licensing subscriptions, product service interactions, or other transactions.</li>
                            <li>Targeted Advertisements: Should you prefer not to have us employ the data we gather from you, or that you provide to us, to deliver advertisements in alignment with our advertisers' target-audience preferences, ensure your browser or smartphone settings are adjusted to accept browser cookies. It's worth noting that this opt-out requires browser or smartphone settings configured to accept browser cookies.</li>
                        </ul>
                        <p>It's imperative to acknowledge that we don't govern third-party collection or utilization of your data for interest-based advertising. Nevertheless, these third parties might offer ways for you to decline data collection or utilization for this purpose. To abstain from receiving targeted ads from members of the Network Advertising Initiative ("NAI"), visit NAI's website. Rest assured, the content provided has been meticulously crafted to ensure its originality and uniqueness.</p>
                        <h5 id="hedthj13">Accessing and Rectifying Your Data</h5>
                        <p>For those who have established an account on our Website, the ability to review and modify personal data is accessible through account login and visiting the account profile page. Correspondingly, you can send an email to support@fareslist.com to initiate access to, amendment, or removal of any personal details you've shared with us. However, erasure of personal data necessitates concurrent deletion of your user account. It's important to note that modifications might be declined if they're suspected of violating any legal statute or requirement or if they could lead to inaccurate information.</p>
                        <p>Should you decide to erase your User Contributions from the Website, copies of these contributions might still be viewable within cached or archived pages or could potentially be duplicated or retained by fellow Website users. The appropriate handling and utilization of data provided on the Website, including User Contributions, are guided by our Terms and Conditions as outlined in the Fareslist - Terms and Conditions section. If you're a resident of specific states within the United States, additional rights and choices regarding personal data may be applicable. For specific details, please refer to the privacy rights section pertinent to your state, if applicable.</p>
                        <p>Be assured that every endeavor has been made to ensure the originality and uniqueness of the rewritten content.</p>
                        <h5 id="hedthj14">Ensuring Information Security</h5>
                        <p>Our commitment to safeguarding and fortifying your data is unwavering. We have implemented widely-accepted measures specifically designed to shield your personal data against inadvertent loss and unauthorized access, utilization, modification, and disclosure. All data shared with us is securely stored on our protected servers, which are fortified by firewalls. Additionally, any payment transactions undergo encryption through SSL technology.</p>
                        <p>However, it's essential to acknowledge that the complete security of information transmission over the internet can never be guaranteed. Despite our meticulous application of reasonable security precautions, the transmission of personal information online inherently carries risks. The responsibility for any such transmission lies with the sender. It's important to recognize that we bear no responsibility for evading privacy settings or security mechanisms present on the Website.</p>
                        <p>In case we become aware of a compromise in your personal information, we will make efforts to inform you through the most recent email address on record, with a suitable timeframe that conforms to prevailing circumstances and is in alignment with applicable law. Nonetheless, we might need to delay notification as we assess the extent of the breach, endeavor to restore system integrity and cooperate with law enforcement.</p>
                        <p>Your data's safety also rests in your hands. If you possess a password for specific sections of our Website, the confidentiality of this password is your responsibility. Kindly refrain from sharing your password with others. We advise caution when sharing information in public areas of the Website, as data shared in such domains is accessible to all Website users.</p>
                        <p>Rest assured, utmost care has been taken to ensure the uniqueness and originality of the rewritten content.</p>
                        <h5 id="hedthj15">Preservation of Your Data</h5>
                        <p>We will uphold your personal information in adherence to all pertinent legal statutes, exclusively for the duration necessary to fulfill the aims outlined in this Policy. Your personal information, before being employed for analytical objectives or trend analysis beyond the customary retention span, will be rendered unidentifiable, amalgamated, or otherwise anonymized. Our practices of eliminating your personal information incorporate industry-standard techniques to guarantee irretrievability. To safeguard our systems from potential harm, residual copies of your data might be maintained in backup systems. However, this data remains inaccessible unless it is reinstated, after which all superfluous information is promptly eradicated.</p>
                        <p>The yardsticks employed to ascertain retention durations encompass:</p>
                        <ul>
                            <li>User Relationship Span: The extent of our association with you, encompassing active user accounts, recent bookings, or any other transactions conducted through us.</li>
                            <li>Legal Stipulations: Obligations stemming from legal norms mandating the maintenance of records for specific timeframes about your transactions with us.</li>
                            <li>Legal Commitments: Relevant legal obligations dictating the duration for which we retain your data, which include contractual responsibilities, legal constraints, statutes of limitations, and regulatory inquiries.</li>
                            <li>System Safeguarding: Whether the data is essential for secure backups of our systems.</li>
                        </ul>
                        <p>Be assured that meticulous effort has been invested to ensure the authenticity and distinctiveness of the revised content.</p>
                        <h5 id="hedthj16">Handling Your Data</h5>
                        <p>The data you furnish could be relocated, accessed, stored, and managed within the United States and additional nations. The data protection regulations and other norms in these regions might not be as all-encompassing as those in your native country. As you engage with the Website, you recognize and consent to the potential transfer of your data to these nations. This includes, but isn't confined to, our facilities and the third parties detailed in this Policy, all of which operate within these nations.</p>
                        <p>We impose limitations on data transfers, ensuring they align with legal obligations or are pivotal for the fulfillment of our business responsibilities. Rest assured, the reworded content is entirely unique and original.</p>
                        <h5 id="hedthj17">Global Data Movement</h5>
                        <p>The personal data we handle might be reached, processed, or transferred to regions distinct from your country of residence. These regions might have data protection regulations that diverge from those in your country.</p>
                        <p>The servers underpinning our platform are situated within the United States, and Fareslist, along with its third-party service providers, operates across various countries globally. As we gather your data, processing might occur within any of these countries. Access to your personal data by our staff may span multiple international locations. The recipients of your personal data could also be based in regions outside your country of residence.</p>
                        <p>To ensure the security and compliance of any personal data access, processing, and/or transfer, we have instituted apt measures and precautions. These endeavors aim to uphold the stipulations of this Policy and adhere to relevant data protection legislation. These actions are designed to afford your data a level of protection that is at a minimum on par with local laws in your country, regardless of the origin, processing, and/or destination of your data. Be assured that considerable care has been taken to craft the revised content originally and distinctively. These measures encompass the following steps:</p>
                        <ul>
                            <li>European Commission's Adequacy Decisions: We acknowledge the adequacy determinations issued by the European Commission that affirm a satisfactory level of data safeguarding within corresponding non-European Economic Area (non-EEA) nations.</li>
                            <li>Third-Party Partners and Providers: We diligently ensure that third-party associates, vendors, and service providers, with whom data is shared, maintain appropriate safeguards for the protection of your data. For example, agreements signed with these entities include stringent clauses governing data transfers. This encompasses the utilization of the European Commission's Standard Contractual Clauses, issued by the European Commission and/or the United Kingdom (if relevant) for transfers originating from the EEA/UK. These contractual terms mandate all parties to shield the personal data they handle in compliance with applicable data protection regulations.</li>
                            <li>Periodic Risk Assessments and Technological Safeguards: We conduct regular evaluations of potential risks and adopt an array of technological and organizational measures. These measures are designed to ensure alignment with pertinent data transfer laws.</li>
                        </ul>
                        <p>Be assured that utmost care has been taken to preserve the originality and distinctiveness of the revised content.</p>
                        <h5 id="hedthj18">Notification of Rights for Individuals within the EU</h5>
                        <p>For those situated within the confines of the European Economic Area (EEA), this Policy, along with the subsequent notifications, is tailored to align with the EU General Data Protection Regulation (GDPR). The GDPR, Regulation (EU) 2016/679, ratified by the European Parliament and the Council on 27 April 2016, is adhered to in a manner that ensures fitting care and safeguarding for the handling of your user data.</p>
                        <p>Our collection, retention, and utilization of such data are meticulously confined to essentials necessary for furnishing our products and/or services, always compliant with pertinent data retention statutes. In your capacity as a "Data Subject" within the GDPR's scope, you are bestowed with specific rights that can be exercised to protect your data at any juncture. These rights include:</p>
                        <ul>
                            <li>The Right to Information: You are entitled to inquire about the personal data we process concerning you and the reasoning behind such processing.</li>
                            <li>The Right of Access to Information: You hold the prerogative to request a copy of your personal data in our custody, including the data collected and processed. Should we decline your access request, we will furnish the rationale, allowing you to register a complaint with an applicable data protection supervisory authority regarding our handling of your identifiable data.</li>
                            <li>The Right of Rectification: You possess the right to rectify any inaccuracies or omissions in the data we gather and process concerning you.</li>
                            <li>The Right to Processing Restriction: If circumstances apply, such as a pending legal dispute or data correction underway, you hold the right to temporarily limit the processing of your data.</li>
                            <li>The Right to Erasure (Right to Be Forgotten): Under specific conditions, you can seek the erasure of your data from our records.</li>
                            <li>The Right to Data Portability: You hold the right to have your data, collected by us, transferred to another organization. This data should be furnished in a structured, machine-readable format commonly employed.</li>
                            <li>The Right to Withdraw Consent: You maintain the right to retract consent previously granted for the processing of your personal data for a distinct purpose.</li>
                            <li>The Right to Object: You retain the right to object to certain forms of processing, including personal data usage for direct marketing and advertising. This includes objecting to profiling where relevant, particularly if tied to direct marketing and advertising.</li>
                            <li>The Right to Lodge a Complaint: You have the prerogative to complain with a local supervisory authority, employing a method sanctioned by them.</li>
                        </ul>
                        <p>Assurance is provided that considerable effort has been invested in ensuring the authenticity and distinctiveness of the rephrased content.</p>
                        <p>The Data Subject is empowered to invoke these rights by communicating with the relevant party. By pertinent regulations, your data shall not be processed and/or divulged without a lawful basis, as stipulated in Article 6 of the GDPR. Generally, when processing personal data, at least one of the subsequent conditions must be satisfied:    </p>
                        <ul>
                            <li>Consent: The individual has provided explicit consent for the processing of their personal data with a particular intent.</li>
                            <li>Contract: Processing is imperative for fulfilling contractual obligations.</li>
                            <li>Legal Obligation: Processing is necessary to adhere to legal requirements.</li>
                            <li>Vital Interests: Processing is essential to protect an individual's life.</li>
                            <li>Public Task: Processing is required to execute a task in the public's interest, grounded in clear legal provisions.</li>
                            <li>Legitimate Interests: Processing is necessary for the legitimate interests of the data controller, except in cases where safeguarding an individual's personal data takes precedence over those legitimate interests.</li>
                        </ul>
                        <h5 id="hedthj19">Notice to Canadian Residents Regarding Your Rights</h5>
                        <p>As a Canadian resident, you possess the privilege to solicit that we divulge specific details about the collection and utilization of your personal information unless such a request proves infeasible or would impose an undue burden on Fareslist. You also retain the right to contest the accuracy and comprehensiveness of the information, and to have it rectified as necessary. Upon receipt and verification of your legitimate request, we will provide you with:</p>
                        <ul>
                            <li>The classifications of personal information we have gathered about you.</li>
                            <li>The origins of the personal information we have amassed about you.</li>
                            <li>The business or commercial intent behind the accumulation or dissemination of said personal information.</li>
                            <li>The classifications of external parties with whom we share this personal information.</li>
                        </ul>
                        <p>Within the boundaries delineated by relevant law and contractual obligations, we are committed to rectifying or adjusting inaccurate or deficient personal information, acknowledging any disputes received, and notifying pertinent third parties accordingly.</p>
                        <p>To exercise the aforementioned rights, kindly submit a valid consumer request to us by reaching out via telephone at any hour of the day. Our dedicated team is at your service 24/7, prepared to provide the assistance you require.</p>
                        <h5 id="hedthj20">For your request to be valid:</h5>
                        <ul>
                            <li>You must furnish adequate information enabling us to reasonably verify your identity as the individual for whom we collected personal information or as an authorized representative.</li>
                            <li>You must delineate your request in sufficient detail, facilitating our comprehensive understanding, assessment, and response to it.</li>
                        </ul>
                        <p>We are regrettably unable to address your request or furnish personal information if we are unable to validate your identity or ascertain your authorization to make the request, while also confirming that the personal information pertains to you. It's essential to note that the submission of a verifiable consumer request does not necessitate the creation of an account. Any personal information provided within a verifiable consumer request will solely be used to verify the requester's identity or authorization to submit the request. On occasions, we may request a copy of your government-issued identification to confirm that you are indeed the individual whose information we have collected or acting as their authorized representative.</p>
                        <p>We strive to reply to a verifiable consumer request within 30 days of its reception. If the need for an extension arises (up to 60 days), we will communicate the rationale and the duration of the extension in written form. If you possess an account with us, we will direct our written response to that account. In the absence of an account, we will send our written response either via mail or electronically, according to your preference. The response will also elucidate reasons behind our inability to comply with a request, where applicable.</p>
                        <p>We impose no fees for processing or addressing your verifiable consumer request, unless it falls under the categories of being excessive, repetitive, or evidently baseless. Should we determine that a fee is warranted, we will furnish an explanation for our decision and provide an estimate of costs before concluding your request.</p>
                        <h5 id="hedthj21">Notice to Residents of California; California Consumer I Notification:</h5>
                        <p>Pursuant to the California Consumer Privacy Act of 2018 ("CCPA"), as amended by the California Privacy Rights Act of 2020 ("CPRA"), certain rights are afforded to California residents concerning the collection, utilization, and sharing of their personal data.</p>
                        <p>Under California Civil Code § 1798.83, California residents who avail themselves of our Website hold the prerogative to request specific information pertaining to the disclosure of their personal data to third parties for the purposes of direct marketing. Kindly forward such requests via email to support@fareslist.com.</p>
                        <h5 id="hedthj22">Notice to Residents of Nevada; Nevada Privacy Rights Notification:</h5>
                        <p>Under Nevada law (SB 220), Nevada consumers are entitled to opt out of the sale of particular personal information collected by website operators. In the past twelve (12) months,suport@fareslist.com has refrained from selling your personal data to third parties (as delineated by Nevada law) and is committed to maintaining this stance in the future, accompanied by requisite notice and an avenue for opting out of such sales as mandated by law.</p>
                        <h5 id="hedthj23">Notice to Residents of Virginia; Virginia Privacy Rights Notification:</h5>
                        <p>Commencing January 1, 2023, in accordance with Virginia’s Consumer Data Protection Act ("CDPA"), the rights of Virginia residents pertaining to the gathering, application, and sharing of their personal data come into effect. Over the previous twelve (12) months, Fareslist has neither sold your personal data nor does it intend to do so without adhering to the stipulated legal requirement of notifying you and providing an opportunity to opt out of such transactions. support@fareslist.com might participate in "targeted advertising" as defined in the CDPA.</p>
                        <p>Beginning January 1, 2023, you are vested with the right to abstain from participating in targeted advertising. Fareslist collects diverse categories of personal data when you interact with the Website, the App, or our services, detailed in the section titled "Information We Collect About You and How We Use It." Furthermore, the "Disclosure of Your Information" segment outlines the parties with whom we might share personal data and elucidates the circumstances under which specific data may be shared.</p>
                        <p>Effective January 1, 2023, you are empowered to:</p>
                        <ul>
                            <li>Solicit access to and insight into the personal data amassed about you.</li>
                            <li>Request rectification of any inaccuracies within your personal data.</li>
                            <li>Seek the deletion of your personal data, with exceptions in accordance with the CDPA and other applicable laws that might allow us to retain and employ specific personal data despite your deletion request.</li>
                            <li>Obtain verification regarding the processing of your personal data.</li>
                            <li>Secure a copy of your personal data.</li>
                        </ul>
                        <p>We commit to refraining from discriminatory actions against you for exercising any of your CDPA rights. Subject to the allowances within the CDPA, we will not:</p>
                        <ul>
                            <li>Withhold goods or services from you.</li>
                            <li>Subject you to variable prices or rates for goods or services, including through the provision of discounts or other incentives, or the imposition of penalties.</li>
                            <li>Furnish you with a dissimilar level or quality of goods or services.</li>
                            <li>Propose that you might receive distinct prices or rates for goods or services or a different level or quality of goods or services.</li>
                        </ul>
                        <p>To invoke one or more of your rights, or to initiate an appeal in the case of denial of a request, please reach out to us at: support@fareslist.com or call +1+866-660-6330.</p>
                        <h5 id="hedthj24">Notice to Residents of Colorado; Colorado Privacy Rights Notification:</h5>
                        <p>Effective from July 1, 2023, residents of Colorado will be endowed with specific privileges concerning the compilation, utilization, and dissemination of their personal data pursuant to the Colorado Consumer Privacy Act ("CPA"). Within the past twelve (12) months, Fareslist has refrained from vending your personal data and has no intention of doing so in the future without according you due notice and the opportunity to decline such transactions in accordance with the legal mandate. Fareslist may partake in "targeted advertising," as defined within the bounds of the CPA. Starting July 1, 2023, you will be empowered to opt out of targeted advertising.</p>
                        <p>Fareslist accumulates diverse categories of personal data during your interactions with the Website, the App, and our services, as delineated in the "Information We Collect About You and How We Use It" section. The segment titled "Disclosure of Your Information" elaborates on the entities with whom we might share personal data, along with elucidating the circumstances wherein specific information may be shared.</p>
                        <p>From July 1, 2023, you hold the right to:</p>
                        <ol>
                            <li>Petition for access to and insight into the personal data amassed concerning you.</li>
                            <li>Seek correction of inaccuracies within your personal data.</li>
                            <li>Request the deletion of your personal data, with exceptions in compliance with the CPA and other pertinent laws that might allow us to uphold and employ particular personal data despite your request for deletion.</li>
                            <li>Secure a copy of your personal data.</li>
                        </ol>
                        <p>To initiate the exercise of one or more of these rights, or to appeal a denial of a request, please establish contact with us via support@fareslist.com or call +1 877-658-0111.</p>
                        <h5 id="hedthj25">Notice to Residents of Connecticut; Connecticut Privacy Rights Notification:</h5>
                        <p>Commencing from July 1, 2023, residents of Connecticut will acquire specific entitlements in relation to the compilation, utilization, and dissemination of their personal data as stipulated by Connecticut's Data Privacy Act ("CDA"). Over the past twelve (12) months, Fareslist has refrained from vending your personal data and has no intention of doing so in the future without providing you with requisite notice and the option to abstain from such transactions in compliance with applicable law. Fareslist may participate in "targeted advertising," as delineated within the framework of the CPA. As of July 1, 2023, you will be authorized to decline participation in targeted advertising.</p>
                        <p>Fareslist collects a diverse range of personal information when you utilize the Website and its Services, details of which are outlined in the "Information We Collect About You and How We Use It" section. The segment named "Disclosure of Your Information" delineates the third parties with whom we might share personal data, along with elucidating the circumstances under which specific information may be shared.</p>
                        <p>Starting from July 1, 2023, you hold the right to:</p>
                        <ol>
                            <li>Request access to and insights into the personal data amassed pertaining to you.</li>
                            <li>Request rectification of inaccuracies within your personal data.</li>
                            <li>Request deletion of your personal data, subject to exceptions under the CDA and other pertinent laws which might authorize the retention and utilization of particular personal data despite your deletion request.</li>
                            <li>Obtain a copy of your personal data.</li>
                        </ol>
                        <p>To initiate the exercise of one or more of these rights, or to challenge the denial of a request, please make contact with us via support@fareslist.com or call +1 877-658-0111.</p>
                        <h5 id="hedthj26">Updates to Our Privacy Policy:</h5>
                        <p>It is possible that alterations to our privacy and data protection disclosures may be necessary due to shifts in our services, changes in relevant laws and regulations, or various other reasons. Consequently, we maintain the prerogative to amend this Policy as needed. Our practice entails announcing any revisions made to our privacy policy on this specific page. You bear the responsibility of ensuring that we have an accurate, operational, and reachable email address for you, and of periodically accessing our Website and this Policy to ascertain any modifications.</p>
                        <p>The date of the most recent update to the policy can be found at the page's commencement. Changes to this Policy will take effect upon their posting unless otherwise specified.</p>
                        <h5 id="hedthj27">Contact Details:</h5>
                        <p>Fareslist retains the right to adjust its Privacy Policy at any time, irrespective of the hour. Therefore, it is advisable to direct inquiries or provide feedback concerning our Privacy Policy and privacy protocols, or to contact the data protection coordinator, by reaching out to us at: support@fareslist.com.</p>
                    </div>
                    <a id="button2424"></a>
                </div>
                
            </div>
        </div>
    </section>
    </>
  )
}
