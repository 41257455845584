import React, { useEffect } from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import SearchEngine from '../Shared/SearchEngine';
import offer from '../Resources/img/offer.png';
import hrsSup from '../Resources/img/24-hours-support.png';
import flight from '../Resources/img/flight.png';
import cyberSec from '../Resources/img/cyber-security.png';
import planeVector from '../Resources/img/Plane_vector_img.png';
import northAmerica from '../Resources/img/North-america.webp';
import africa from '../Resources/img/Africa.webp';
import australia from '../Resources/img/Australia.webp';
import southAmerica from '../Resources/img/South-america.webp';
import europe from '../Resources/img/Europe.webp';
import asia from '../Resources/img/asia.webp';
import newyork from '../Resources/img/New-York.webp';
import argentina from '../Resources/img/argentina.webp';
import amsterdam from '../Resources/img/amsterdam.webp';
import barcelona from '../Resources/img/barcelona.webp';
import dubai from '../Resources/img/dubai.webp';
import bangkok from '../Resources/img/bangkok.webp';
import ticketFlight from '../Resources/img/ticket-flight.png';
import transport from '../Resources/img/transport.png';
import economy from '../Resources/img/economy.png';
import cancelled from '../Resources/img/cancelled.png';
import flight2 from '../Resources/img/flight2.webp';
import flightw from '../Resources/img/FlightW.webp';
import lasLos from '../Resources/img/Los-Angeles---Las-Vegas.webp';
import dallasDenver from '../Resources/img/Dallas---Denver.webp';
import sfolon from '../Resources/img/San-Francisco---London.webp';
import miamx from '../Resources/img/Miami---México.webp';
import seatlehonolulu from '../Resources/img/Seattle----Honolulu.webp'
import dem from '../Resources/img/1233.webp';
import fb from '../Resources/img/fb.webp';
import rt from '../Resources/img/rt.png';
import insta from '../Resources/img/insta.webp';
import twitter from '../Resources/img/twitter.webp';
import pinterest from '../Resources/img/pinterest.webp';
import $ from 'jquery';
import 'slick-carousel';
import Meta from './MetaTags/Meta';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Home() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  useEffect(() => {
    $(document).ready(() => {
      $('.slick-slider1').slick({
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 2000, // Set autoplay speed in milliseconds
        dots: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1
            }
          }
        ]
      });
    });
  }, []);
  return (
    <>
    <Meta url={`${apiIP}/home`} pageTitle="Book Flight Tickets, Hotels, & Holiday Vacation | Airfare Discount -Farelist" pageDescription="Discover unbeatable fares on cheapest flights, airline tickets, Hotels, & Holiday Vacation, and exclusive airfare discounts at Farelist. Book your next adventure today and save big on travel!" />
    <SearchEngine />
      <section className="hide-mobile">

<div className="container">
  <div className="row">
    <div className="col-md-6 col-lg-3 mb-2">
      <div className="card ">
        <img src={offer} alt="" width="50px " />
        <p className="heading mb-0">
          Best Price Promise
        </p>
        <p>
          We offer you one of the most incredible flight deals taking you to countless places.
        </p>
       </div>
    </div>
    <div className=" col-md-6 col-lg-3 mb-2">
      <div className="card ">
        <img src={hrsSup} alt="" width="50px " />
        <p className="heading mb-0">
          24/7 Support
        </p>
        <p>
          When you are in need of airline help, our travel experts are there around-the-clock!
        </p>
       </div>
    </div>
    <div className="col-md-6 col-lg-3 mb-2">
      <div className="card ">
        <img src={flight} alt="" width="50px " />
        <p className="heading mb-0">
          Top Destinations
        </p>
        <p>
          At Fareslist, we vow to take you to all of your dream destinations at affordable prices.
        </p>
       </div>
    </div>
    <div className="col-md-6 col-lg-3 mb-2">
      <div className="card ">
        <img src={cyberSec} alt="" width="50px " />
        <p className="heading mb-0">
          Safe & Secure
        </p>
        <p>
          Booking with us is the safest way and with our 360 degree secure payments, you can be worry-free!
        </p>
       </div>
    </div>
    
  </div>
</div>
</section>


<section className="rela hide-mobile">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-12 text-center mb-3">
      <img className="draw" src={planeVector} alt="" />
      <h2>Explore Your Dream Destinations Without Spending a Fortune! </h2>
      <p>Discover the most affordable flights to incredible destinations around the world with Fareslist. Experience the thrill of travel without the hefty price tag. Without compromising your budget, fly to Paris, Singapore, Australia, & more! </p>
    </div>
    <div className="col-md-6 col-lg-3">
      <Link to='/north-america'>
        <div className="rela mb-2 swert mt-3">
          <img className="imge-round" src={northAmerica} alt="" width="100%" height="200px" />
        </div>
      </Link>
    </div>
    <div className="col-md-6 col-lg-3">
      <Link to='/africa'>
        <div className="rela mb-2 swert mt-3">
          <img className="imge-round" src={africa} alt="" width="100%" height="200px" />
        </div>
      </Link>
    </div>
    <div className="col-md-6 col-lg-3">
      <Link to='/australia'>
      <div className="rela mb-2 swert mt-3">
        <img className="imge-round" src={australia} alt="" width="100%" height="200px" />
      </div>
      </Link>
    </div> 
    <div className="col-md-6 col-lg-3">
      <Link to='/south-america'>
      <div className="rela mb-2 swert mt-3">
        <img className="imge-round" src={southAmerica} alt="" width="100%" height="200px" />
      </div>
      </Link>
    </div> 
    <div className="col-md-6 col-lg-3">
      <Link to='/europe'>
      <div className="rela mb-2 swert mt-3">
        <img className="imge-round" src={europe} alt="" width="100%" height="200px" />
      </div>
      </Link>
    </div> 
    <div className="col-md-6 col-lg-3">
      <Link to='/asia'>
      <div className="rela mb-2 swert mt-3">
        <img className="imge-round" src={asia} alt="" width="100%" height="200px" />
      </div>
      </Link>
    </div> 
    
  </div>
</div>
</section>

<section className="cvkfujg hide-mobile">
<div className="container text-center">
  <h2>Worldwide Destinations</h2>
      <p className="mb-4">We Offer countless destinations to suit various interests from history and culture to natural beauty and gastronomy</p>
  <div className="row justify-content-center">
    <div className="col-6 col-md-3 col-lg-2">
      <Link to='/'>
      <div className="citgh">
        <img src={newyork} alt="" width="100%" />
        <p className="mt-2"><b>New York</b></p>
      </div>
      </Link>
    </div>
    <div className="col-6 col-md-3 col-lg-2">
      <Link to='/'>
      <div className="citgh">
        <img src={argentina} alt="" width="100%" />
        <p className="mt-2"><b>Argentina</b></p>
      </div>
      </Link>
    </div>
    <div className="col-6 col-md-3 col-lg-2">
      <Link to='/'>
      <div className="citgh">
        <img src={amsterdam} alt="" width="100%" />
        <p className="mt-2"><b>Amsterdam</b></p>
      </div>
      </Link>
    </div>
    <div className="col-6 col-md-3 col-lg-2">
      <Link to='/'>
      <div className="citgh">
        <img src={barcelona} alt="" width="100%" />
        <p className="mt-2"><b>Barcelona</b></p>
      </div>
      </Link>
    </div>
    <div className="col-6 col-md-3 col-lg-2">
      <Link to='/'>
      <div className="citgh">
        <img src={dubai} alt="" width="100%" />
        <p className="mt-2"><b>Dubai</b></p>
      </div>
      </Link>
    </div>
    <div className="col-6 col-md-3 col-lg-2">
      <Link to='/'>
      <div className="citgh">
        <img src={bangkok} alt="" width="100%" />
        <p className="mt-2"><b>Bangkok</b></p>
      </div>
      </Link>
    </div>

    <div className="mt-4 ">
      <Link to='/sitemap'>
        <button className="btnff"><i className="animationff"></i>View More<i className="animationff"></i>
      </button>
    </Link>
  </div>
   
  </div>
</div>
</section>

<section className="chng-inclg">
<div className="container">
  <div className="row align-items-center">
    <div className="col-lg-6">
      <h5><i>Choosing Fareslist is</i></h5>
      <h3>Your Path to the Best Flight Deals</h3>
      <p>Finding the cheapest flights has never been simpler! With Fareslist, score amazing travel deals in just a few clicks. Choose your next journey and enjoy the savings while you fly to your favorite destinations around the globe. Be it at the last minute or from the top 10 airlines, you get the most affordable airfares right here with us. What do you have to do? Well, just give us a call and we will handle the rest!</p>

      <div className="row">
        <div className="col-md-6 col-lg-6">
          <div className="chngt d-flex align-items-center mb-3">
            <img src={ticketFlight} alt="" width="32px" />
              <p className="mb-0 ms-2"><b>Easy Bookings</b></p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="chngt d-flex align-items-center mb-3">
            <img src={transport} alt="" width="32px" />
              <p className="mb-0 ms-2"><b>Flight Changes</b></p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="chngt d-flex align-items-center mb-3">
            <img src={economy} alt="" width="32px" />
              <p className="mb-0 ms-2"><b>Cheapest Airfare</b></p>
          </div>
        </div>
        
        <div className="col-md-6 col-lg-6">
          <div className="chngt d-flex align-items-center mb-3">
            <img src={cancelled} alt="" width="32px" />
              <p className="mb-0 ms-2"><b>Fast Cancellations</b></p>
          </div>
        </div>
      </div>
      
    </div>
    <div className="col-6 col-lg-3">
      <img className="roung" src={flight2} alt="" width="100%" />
    </div>
    <div className="col-6 col-lg-3">
      <img className="roung" src={flightw} alt="" width="100%" />
    </div>
  </div>
</div>
</section>

<section className="cvkfujg">
<div className="container">
  <div className="row">
    <div className="col-lg-12 text-center">
      <h2>Popular Flight Routes</h2>
      <p>Amazing memories, adventures, and explorations does not need be too expensive! </p>
      <div className="slick-slider1">
        <div className="rela ovrufv">
          <Link to='/losangeles-lasvegas'>
            <img className="rounde1" src={lasLos} alt="Image 1" width="100%" />
          </Link>
      </div>
        <div className="rela ovrufv">
          <Link to='/dallas-denver'>
          <img className="rounde1" src={dallasDenver} alt="Image 2" width="100%" />
          </Link>
        </div>
        <div className="rela ovrufv">
          <Link to='/sanfrancisco-london'><img className="rounde1" src={sfolon} alt="Image 3" width="100%" / >
            </Link>
        </div>
        <div className="rela ovrufv">
          <Link to='/miami-mexico'>
          <img className="rounde1" src={miamx} alt="Image 4" width="100%" />
          </Link>
        </div>
        <div className="rela ovrufv">
          <Link to='/seatle-honolulu'>
          <img className="rounde1" src={seatlehonolulu} alt="Image 4" width="100%" />
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

<section className="rela odhyr">
<img className="posir spin2323" src={dem} alt="" />
<div className="container">
  <div className="row justify-content-center text-center">
    <h2>Connect with us in our Socials, y’all!</h2>
      <p>Reach out to us in our venture to network in all of the leading social media platforms!</p>
    <div className="col-md-6 col-lg-3 text-start mb-2">
        <div className="solic rela">
          <img className="rujt" src={fb} alt="" width="100%" />
          <div className="contefrt">
            <div className="d-flex align-items-center justify-content-center mr-topp">
              {/* <!-- <p className="hefg mb-0"><b>FACEBOOK</b></p>  --> */}
              <div className="social1 me-2">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </div>
            </div>
            
          <p>We are here to help you recall your best times and create new unforgettable moments! Follow our FB page for a daily dose of inspiration, travel tips, and exclusive deals.</p>
          <a href="https://www.facebook.com/fareslistus/" target="_blank" className="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img className="arrow-right1" src={rt} alt="" /></a>
          </div>
        </div>
    </div>
    <div className="col-md-6 col-lg-3 text-start mb-2">
        <div className="solic rela">
          <img className="rujt" src={insta} alt="" width="100%" />
          <div className="contefrt">
            <div className="d-flex align-items-center justify-content-center mr-topp">
              {/* <!-- <p className="hefg mb-0"><b>INSTAGRAM</b></p>  --> */}
              <div className="social1 me-2">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </div>
            </div>
          <p>Connect with us on Insta for recent updates on airline industry and even get all the latest & exclusive flight deals from our company! While scrolling reels and memes, don't miss out on airline deets.</p>
          <a href="https://www.instagram.com/fareslist/" target="_blank" className="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img className="arrow-right1" src={rt} alt="" /></a>
          </div>
        </div>
    </div>
    <div className="col-md-6 col-lg-3 text-start mb-2">
        <div className="solic rela">
          <img className="rujt" src={twitter} alt="" width="100%" />
          <div className="contefrt">
            <div className="d-flex align-items-center justify-content-center mr-topp">
              {/* <!-- <p className="hefg mb-0"><b>TWITTER</b></p>  --> */}
              <div className="social1 me-2">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </div>
            </div>
          <p>Looking for instant answers or the latest buzz from Fareslist? Follow us for latest tweets from airline, where your queries get quick answers and updates are just a tweet away.</p>
          <a href="https://twitter.com/Fareslistus" target="_blank" className="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img className="arrow-right1" src={rt} alt="" /></a>
          </div>
        </div>
    </div>
    <div className="col-md-6 col-lg-3 text-start mb-2">
        <div className="solic rela">
          <img className="rujt" src={pinterest} alt="" width="100%" />
          <div className="contefrt">
            <div className="d-flex align-items-center justify-content-center mr-topp">
              {/* <!-- <p className="hefg mb-0"><b>PINTEREST</b></p>  --> */}
              <div className="social1 me-2">
                <i className="fa fa-pinterest-p" aria-hidden="true"></i>
              </div>
            </div>
          <p>Stay in the loop with the latest news and updates! From exciting promotions to insider tips on travel destinations, our company pins you about airlines in every step of the way.</p>
          <a href="https://www.pinterest.com/fareslist/" target="_blank" className="d-flex justify-content-center align-items-center fredt"><b><i>View More</i></b> <img className="arrow-right1" src={rt} alt="" /></a>
          </div>
        </div>
    </div>

    <div className="mt-4 ">
      <Link to='/social'>
        <button className="btnff"><i className="animationff"></i>FOLLOW OUR SOCIALS<i className="animationff"></i>
      </button>
    </Link>
  </div>

    
  </div>
 

</div>

</section>
<a id="button2424"></a>
    </>
  )
}
