import React from 'react';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import '../Resources/assest/css/style.css'
import Argentina from '../Resources/assest/img/Main/SouthAmericaCountries/Argentina.webp';
import Bolivia from '../Resources/assest/img/Main/SouthAmericaCountries/Bolivia.webp';
import Brazil from '../Resources/assest/img/Main/SouthAmericaCountries/Brazil.webp';
import Chile from '../Resources/assest/img/Main/SouthAmericaCountries/Chile.webp';
import Colombia from '../Resources/assest/img/Main/SouthAmericaCountries/Colombia.webp';
import Paraguay from '../Resources/assest/img/Main/SouthAmericaCountries/Paraguay.webp';
import Peru from '../Resources/assest/img/Main/SouthAmericaCountries/Peru.webp'
import Uruguay from '../Resources/assest/img/Main/SouthAmericaCountries/Uruguay.webp';
import Aruba from '../Resources/assest/img/Small/SouthAmericaCountries/Aruba.webp';
import Curacao from '../Resources/assest/img/Small/SouthAmericaCountries/Curacao.webp';
import Ecuador from '../Resources/assest/img/Small/SouthAmericaCountries/Ecuador.webp';
import FrenchGuiana from '../Resources/assest/img/Small/SouthAmericaCountries/French-Guiana.webp';
import Guyana from '../Resources/assest/img/Small/SouthAmericaCountries/Guyana.webp';
import Suriname from '../Resources/assest/img/Small/SouthAmericaCountries/Suriname.webp'
import SearchEngine from '../Shared/SearchEngine';

export default function SouthAmerica() {
  return (
   <>
   <SearchEngine />
   <section class="rela">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mb-3">
            <h2>Dancing with Diversity in South America</h2>
            <p>Lose yourself in Brazil's rhythmic beats or uncover the mysteries of Peru's ancient ruins. Argentina's tango and Chile's dramatic landscapes promise a journey of endless fascination. </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Argentina} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Bolivia} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Brazil} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Chile} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Colombia} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Paraguay} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div> 
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Peru} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          <div class="col-md-6 col-lg-3">
            <Link to='/'>
            <div class="rela mb-2 swert mt-3">
              <img class="imge-round" src={Uruguay} alt="" width="100%" height="200px" />
            </div>
            </Link>
          </div>
          
        </div>
      </div>
    </section>

    <section class="cvkfujg">
      <div class="container text-center">
        <h2>Exploring Lesser-Known Destinations of South America</h2>
            <p class="mb-4">Uncover South America's hidden charms, where Colombia's vibrant cities and Suriname's untouched rainforests beckon. From the rugged beauty of Guyana to the tranquility of Uruguay's countryside, there's no shortage of surprises waiting to be discovered.</p>
        <div class="row justify-content-center">
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Aruba} alt="" width="100%" />
              <p class="mt-2"><b>Aruba</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Curacao} alt="" width="100%" />
              <p class="mt-2"><b>Curacao</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Ecuador} alt="" width="100%" />
              <p class="mt-2"><b>Ecuador</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={FrenchGuiana} alt="" width="100%" />
              <p class="mt-2"><b>French Guiana</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Guyana} alt="" width="100%" />
              <p class="mt-2"><b>Guyana</b></p>
            </div>
            </Link>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <Link to='/'>
            <div class="citgh">
              <img src={Suriname} alt="" width="100%" />
              <p class="mt-2"><b>Suriname</b></p>
            </div>
            </Link>
          </div>

          <div class="mt-4 ">
            <Link to='/sitemap'>
              <button class="btnff"><i class="animationff"></i>View More<i class="animationff"></i>
            </button>
          </Link>
        </div>
         
        </div>
      </div>
    </section>
   </>
  )
}
