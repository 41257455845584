import React, { useEffect } from 'react'
import '../Resources/assest/css/style.css'
import Dallas from '../Resources/assest/img/dallas.webp'
import Denver from '../Resources/assest/img/denver.webp'
import Frontier from '../Resources/assest/img/frontier-airlines.jpg'
import Fb from '../Resources/assest/img/fb.webp'
import United from '../Resources/assest/img/United-airlines.webp'
import American from '../Resources/assest/img/American-airlines.webp'
import Delta from '../Resources/assest/img/Delta.webp'
import Denver2 from '../Resources/assest/img/denver2.webp'
import Denver3 from '../Resources/assest/img/Denver3.webp'
import $ from 'jquery';
import 'slick-carousel';
import SearchEngine from '../Shared/SearchEngine'

export default function DallasDenver() {
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <SearchEngine />
    <section class="rughtd rela odhyr">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Flights from Dallas to Denver</i></p>
                    <h2>Fly from Dallas to Denver Without Spending a Fortune!</h2>
                    <p>Finding budget-friendly flights from Dallas to Denver is simple with a few smart strategies. By choosing the right travel dates and times, you can save significantly on your airfare. Here are some ideas from Fareslist to secure the best deals.</p>
                    <div class="d-lg-flex">
                        <ul>
                            <li>Fly in February.</li>
                            <li>Avoid December travel.</li>
                            <li>Book Tuesday flights.</li>
                            <li>Skip Sunday flights.</li>
                            
                        </ul>
                        <ul class="ms-lg-4 ">
                            <li>Choose evening departures.</li>
                            <li>Book 30 days ahead.</li>
                            <li>Compare airline prices.</li>
                            <li>Fly Dallas/Fort Worth to Denver Intl.</li>
                        </ul>
                    </div>
                   
                </div>
                <div class="col-lg-6 rela">
                    <img class="orfw rela" src={Dallas} alt="" />
                    <img class="ihtf" src={Denver} alt="" />
                </div>
            </div>
        </div>
    </section>

    

    <section class="cvkfujg">
        <div class="container">
          <h2 class="text-center">Want to Explore More Diverse Flight Route Options?</h2>
          <p class="text-center mb-4">Fareslist offers a variety of flight fares from multiple airlines for your Dallas to Denver trip, helping you find the most affordable journey. Call us today and you might get exclusive deals on this route as well!</p>
          <div class="row">
            <div class="col-lg-12">
              <div class="slick-slider12 dtgd">
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Frontier} alt="" width="100%" />
                      <h6 class="sirnb"><i>Frontier Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">DFW - DEN</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Fb} alt="" width="100%" />
                      <h6 class="sirnb"><i>JetBlue Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">DFW - DEN</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={United} alt="" width="100%" />
                      <h6 class="sirnb"><i>United Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">DFW - DEN</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={American} alt="" width="100%" />
                      <h6 class="sirnb"><i>American Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">DFW - DEN</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Delta} alt="" width="100%" />
                      <h6 class="sirnb"><i>Delta</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">DFW - DEN</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                
                
            </div>
        
            </div>
          </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={Denver2} alt="" width="100%" />
                </div>
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Reasons to fly to Denver</i></p>
                    <h3>Why Flying from Dallas to Denver is a Great Choice</h3>
                    <p>Flying from Dallas to Denver offers stunning mountain views, vibrant city life, and endless outdoor adventures. Enjoy hiking, skiing, and exploring Denver's rich cultural scene, making it a perfect destination for all types of travelers.</p>
                </div>
                
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Denver’s best time</i></p>
                    <h3>Optimal Time to Visit Denver for Budget-Friendly Travel and Exciting Events</h3>
                    <p>For affordable travel and vibrant events, plan your visit to Denver in the fall. September to November offers pleasant weather, fewer crowds, and various festivals, providing a perfect balance of savings and entertainment for your trip.</p>
                </div>
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={Denver3} alt="" width="100%" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
