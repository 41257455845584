import React, { useEffect } from 'react'
import '../Resources/assest/css/style.css'
import SFO from '../Resources/assest/img/san-faranciso.webp'
import LON from '../Resources/assest/img/london.webp'
import VirginAtlantic from '../Resources/assest/img/Virgin-Atlantic-Airlines.webp'
import British from '../Resources/assest/img/British-Airways.webp'
import United from '../Resources/assest/img/United-airlines.webp'
import American from '../Resources/assest/img/American-airlines.webp'
import Delta from '../Resources/assest/img/Delta.webp'
import London3 from '../Resources/assest/img/london3.webp'
import London2 from '../Resources/assest/img/london2.webp'
import SearchEngine from '../Shared/SearchEngine'
import $ from 'jquery';
import 'slick-carousel';

export default function SfoLon() {
    useEffect(() => {
        $(document).ready(() => {
          $('.slick-slider12').slick({
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 2000, // Set autoplay speed in milliseconds
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      }, []);
  return (
    <>
    <SearchEngine />
    <section class="rughtd rela odhyr">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Flights from San Francisco to London</i></p>
                    <h2>Pocket-friendly Flights from San Francisco to London</h2>
                    <p>Discovering economical flights from San Francisco to London is possible with smart strategies. By considering key factors such as timing and booking methods, you can save significantly on your journey. Here are some quick tips from Fareslist:</p>
                    <div class="d-lg-flex">
                        <ul>
                            <li>Choose Sunday departure.</li>
                            <li>Avoid Tuesday flights.</li>
                            <li>Prefer afternoon departures.</li>
                            <li>Consider Virgin Atlantic airline.</li>
                            
                        </ul>
                        <ul class="ms-lg-4 ">
                            <li>Check flights with stops.</li>
                            <li>Book San Francisco to London Heathrow.</li>
                            <li>Utilize our website’s prices.</li>
                            <li>Stay flexible with dates.</li>
                        </ul>
                    </div>
                   
                </div>
                <div class="col-lg-6 rela">
                    <img class="orfw rela" src={SFO} alt="" />
                    <img class="ihtf" src={LON} alt="" />
                </div>
            </div>
        </div>
    </section>

    

    <section class="cvkfujg">
        <div class="container">
          <h2 class="text-center">Want to See More Flight Options with Fareslist?</h2>
          <p class="text-center mb-4">Fareslist offers a range of flight fares from various airlines for your San Francisco to London journey, ensuring you find the most suitable and cost-effective options for your trip.</p>
          <div class="row">
            <div class="col-lg-12">
              <div class="slick-slider12 dtgd">
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={VirginAtlantic} alt="" width="100%" />
                      <h6 class="sirnb"><i>Virgin Atlantic Airways</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - LON</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={British} alt="" width="100%" />
                      <h6 class="sirnb"><i>British Airways</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - LON</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={United} alt="" width="100%" />
                      <h6 class="sirnb"><i>United Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - LON</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={American} alt="" width="100%" />
                      <h6 class="sirnb"><i>American Airlines</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - LON</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                <div>
                  <a href="">
                    <div class="slidrf rela mx-3">
                      <img src={Delta} alt="" width="100%" />
                      <h6 class="sirnb"><i>Delta</i></h6>
                      <div class="package-ribbon-wrapper">
                        <div class="package-type normal-type">  <i class="fa fa-clock-o me-1" aria-hidden="true"></i> 11 NOV 2013 - 22 NOV 2013</div>
                        <div class="clear"></div>
                        <div class="package-type-gimmick"></div>
                        <div class="clear"></div>
                      </div>
                      <div class="destina">
                        <h6 class="mb-0">SFO - LON</h6>
                        <h6 class="mb-0"><i class="fa fa-tag me-2" aria-hidden="true"></i>$ 6,000  </h6>
                      </div>
                    </div>
                    <p class="tijmh">  </p>
                  </a>
                </div>
                
                
            </div>
        
            </div>
          </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={London3} alt="" width="100%"/ >
                </div>
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Reasons to visit London</i></p>
                    <h3>Experience the Best of Both Worlds: San Francisco to London</h3>
                    <p>Flying from San Francisco to London offers a unique blend of vibrant city life, rich history, and cultural diversity. Explore iconic landmarks, savor delicious cuisine, and immerse yourself in the charm of both dynamic cities for an unforgettable travel experience.</p>
                </div>
                
                <div class="col-lg-6 mb-3">
                    <p class="mb-0"><i>Optimal time to reach London</i></p>
                    <h3>Best Time to Visit London for Budget-Friendly London Travel and Incredible Events</h3>
                    <p>For affordable travel and vibrant events, consider visiting London in the shoulder seasons of spring and autumn. During these times, you'll find pleasant weather, fewer crowds, and various festivals, making it an ideal time to explore the city without spilling all that is in your pocket.</p>
                </div>
                <div class="col-lg-6 mb-3">
                    <img class="bujhu" src={London2} alt="" width="100%" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
